import React, { useState } from "react";
import Card from "antd/es/card";
import "antd/es/card/style";
import FeatherIcon from "feather-icons-react";
import { capitalize, replace } from "lodash";
import moment from "moment-timezone";

import styles from "./style.module.scss";
import { FlexRow, Heading } from "../../../atoms";
import ViewAppointmentModal from "../../appointments/ViewAppointmentModal";
import { usePermissions } from "../../../../PermissionsProvider/PermissionsProvider";

function AppointmentCard({ business, location, appointmentData }) {
  const { permissions } = usePermissions();

  const [appointment, setAppointment] = useState();
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const onCardClick = (appointment) => {
    let isStaffing = appointment.b2b_type === "staffing";
    let isCorporate = appointment.b2b_type === "corporate";

    if ((isStaffing  && !permissions?.shift?.includes("shift_details") ) || (isCorporate &&  !permissions?.corporate?.includes("shift_details"))) return;

    if (!(isStaffing || isCorporate) && !permissions?.appointment?.includes("read")) return;

    setAppointment({
      id: appointment.id,
      businessId: appointment.business_id,
      locationId: appointment.location_id,
    });
    setIsViewModalOpen(true);
  };

  let {
    business_id,
    b2b_type,
    location_id,
    status,
    providers_count,
    cart_products_count,
    session_date,
    start_time,
    end_time,
    needs_attention,
    partially_filled,
  } = appointmentData;

  needs_attention = needs_attention === "true" ? true : false;
  partially_filled = partially_filled === "true" ? true : false;

  start_time =
    moment(start_time, "HH:mm").minute() > 0
      ? start_time
      : moment(moment(start_time, "hh:mm A").format("HH A"), "HH A").format(
          "h A"
        );

  end_time =
    moment(end_time, "HH:mm").minute() > 0
      ? end_time
      : moment(moment(end_time, "hh:mm A").format("HH A"), "HH A").format(
          "h A"
        );

  return (
    <>
      <Card
        className={styles["appointment-card"]}
        onClick={() => onCardClick(appointmentData)}
      >
        <div className={styles["appointment-card__header"]}>
          <div>
            <Heading className={styles["one-line"]} as="h3">
              {business[business_id]}
            </Heading>
            <div className={styles["appointment-card__subtitle"]}>
              {location[location_id]}
            </div>
          </div>
          <span
            className={`status ${
              status === "completed" ||
              status === "complete" ||
              status === "confirmed"
                ? "Success"
                : partially_filled
                ? "partially-filled"
                : status === "pending" && needs_attention
                ? "error"
                : status === "pending"
                ? "warning"
                : status === "filled" || status === "assigned"
                ? "in-progress"
                : status === "disabled"
                ? "disabled"
                : "error"
            }`}
          >
            {partially_filled
              ? "Partially filled"
              : status === "pending" && needs_attention
              ? "Unfilled"
              : capitalize(status)}
          </span>
        </div>
        <div className={styles["appointment-card__body"]}>
          <FlexRow>
            <FeatherIcon size={18} color="#9299B8" icon="list" />
            <div>{capitalize(replace(b2b_type, /_/g, " ➔ "))}</div>
          </FlexRow>
          <FlexRow>
            <FeatherIcon size={18} color="#9299B8" icon="users" />
            <div>{`${providers_count}/${cart_products_count} Providers`}</div>
          </FlexRow>
          <FlexRow>
            <FeatherIcon size={18} color="#9299B8" icon="calendar" />
            <div>{moment(session_date).format("MM/DD/YY")}</div>
          </FlexRow>
          <FlexRow>
            <FeatherIcon size={18} color="#9299B8" icon="clock" />
            <div>{`${start_time} - ${end_time}`}</div>
          </FlexRow>
        </div>
      </Card>
      {isViewModalOpen && (
        <ViewAppointmentModal
          isOpen={isViewModalOpen}
          appointmentId={appointment.id}
          locationId={appointment.locationId}
          businessId={appointment.businessId}
          onExit={() => setIsViewModalOpen(false)}
        />
      )}
    </>
  );
}

export default AppointmentCard;
