/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Row from "antd/es/row";
import "antd/es/row/style";
import Form from "antd/es/form";
import "antd/es/form/style";
import Select from "antd/es/select";
import "antd/es/select/style";
import FeatherIcon from "feather-icons-react";
import "antd/es/space/style";
import Card from "antd/es/card";
import "antd/es/card/style";
import Checkbox from "antd/es/checkbox";
import "antd/es/checkbox/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";

import Heading from "../../../atoms/heading/heading";
import styles from "./Permissions.module.scss";
import map from "lodash/map";
import useGetBusinessPermissions from "../../../../utilities/hooks/useGetBusinessPermissions";
import StyledInput from "../../../atoms/inputs/StyledInput";
import { compact, concat, filter, find, flatten, get, includes, isEmpty, omitBy, replace, values } from "lodash";
import useUserProfileData from "../../../../utilities/hooks/useUserProfileData";
import { useBusinessIdGlobal } from "../../../../utilities/hooks/useBusinessId";

const getWrapper = (title, children) => {
  return (
    <div className="card-wrapper">
      <Card>
        <div
          className="header"
          style={{
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Heading className="header__text" as="h4">
            {title}
          </Heading>
        </div>
        <div className="body">
          <Card>{children}</Card>
        </div>
      </Card>
    </div>
  );
};

const getbusinessPermissionWithIdsList = (
  selectedDealtypes,
  businessPermissions
) => {
  const permissionList = selectedDealtypes?.map((type) => {
    const dealType = businessPermissions?.find((perm) => perm.id === type);
    return dealType?.permissions.map((item) => ({
      categoryId: dealType.id,
      id: item,
    }));
  });
  return permissionList?.flat();
};

const getPermissionWithIdsList2 = (selectedDealtypes, businessPermissions) => {
  let tempArr = [];
  selectedDealtypes?.map((type) => {
    const dealType = businessPermissions?.find((perm) => perm.id === type)// || perm.subcategories?.some((subcat) => subcat.id === type));
    dealType?.permissions.map((item) => {
      tempArr.push(
        {
          categoryId: dealType.id,
          id: item,
          isGuestBooking: dealType.name?.toLowerCase() === "guest booking"
        })
    });
    if (!isEmpty(dealType?.subcategories)) {
      dealType?.subcategories.map((subcat) => {
        if (includes(selectedDealtypes, subcat?.id)) {
          subcat?.permissions.map((subitem) => {
            tempArr.push(
              {
                categoryId: subcat.id,
                id: subitem,
                isGuestBooking: subcat.name?.toLowerCase() === "guest booking"
              })
          })
        }
      });
    }
  });
  return tempArr;
}

const Permissions = (props) => {
  const {
    form,
    // TODO recheck in all depending components
    permissionsData: { selectedDealTypes, selectedPermission, businessDomain },
    setPermissionsData,
    businessPermissions,
    setHasResellerCategoryInParent,
  } = props;
  const [selectedParentIds, setSelectedParentIds] = React.useState([]);
  const [selectedSubcategoryIds, setSelectedSubcategoryIds] = React.useState({});
  const [selectedPermissionIds, setSelectedPermissionIds] = React.useState({});

  const [selectedPersmissionsWithCategoryIds, setSelectedPersmissionsWithCategoryIds] = React.useState([]);

  React.useEffect(() => {
    setSelectedPersmissionsWithCategoryIds(getPermissionWithIdsList2(selectedDealTypes, businessPermissions));
    if (!isEmpty(selectedPermission) && !isEmpty(selectedDealTypes) && isEmpty(selectedParentIds)) {
      fillInByParentIds(selectedDealTypes, true)
    }
  }, [selectedDealTypes, businessPermissions]);


  const fillInByParentIds = (parentIds, firstTime = false) => {
    let tempObjSub = selectedSubcategoryIds;
    let tempObjPerm = selectedPermissionIds;

    let tempArrSub = [];

    businessPermissions.map((businPerm) => {
      if (parentIds.includes(businPerm.id)) {
        let innerArrPerm = [];

        businPerm?.permissions.map((perm) => {
          innerArrPerm.push(perm.id);
        });
        tempObjPerm[businPerm.id] = innerArrPerm;

        let subsToMap = businPerm?.subcategories?.length === 1 && !includes(selectedDealTypes, businPerm.id) ? businPerm?.subcategories : filter(businPerm?.subcategories, (el) => selectedDealTypes.includes(el.id));
        if (subsToMap?.length) {
          let innerArrSub = [];
          subsToMap.map((subcat) => {
            let subInnerArrPerm = [];

            tempArrSub.push(subcat.id);

            innerArrSub.push(subcat.id);
            subcat?.permissions.map((perm) => {
              subInnerArrPerm.push(perm.id);
            });

            tempObjPerm[subcat.id] = subInnerArrPerm;
          });
          tempObjSub[businPerm.id] = innerArrSub;
        }
      } else {
        tempObjPerm[businPerm.id] = [];
        tempObjSub[businPerm.id] = [];
        if (businPerm?.subcategories?.length) {
          businPerm?.subcategories.map((subcat) => {
            tempObjPerm[subcat.id] = [];
          })
        }
      }
    });

    // update state
    setSelectedParentIds(parentIds);
    setSelectedSubcategoryIds(tempObjSub);
    setSelectedPermissionIds(tempObjPerm);

    let tempArrPerm = flatten(values(omitBy(tempObjPerm, isEmpty)));
    let tempSelectedDealTypes = compact(concat(parentIds, tempArrSub));
    let initialSelectedDealTypes = !firstTime ? tempArrSub : isEmpty(selectedDealTypes) ? tempArrSub : selectedDealTypes;
    // TODO recheck permissions affected by subcategories

    // update parent component and form
    setPermissionsData({
      selectedDealTypes: tempSelectedDealTypes,
      selectedPermission: tempArrPerm
    });
    form.setFieldsValue({
      providers: tempArrPerm,
      ["sub-deal-type"]: initialSelectedDealTypes
    });
  }

  const adjustBySubcategoryIds = (subIds, parent, parentId) => {
    let tempObjPerm = selectedPermissionIds;
    let tempObjSub = selectedSubcategoryIds;
    let tempArrIdsToRemove = [];
    let initialIds = selectedParentIds;

    parent.map((subcat) => {
      if (!subIds.includes(subcat.value)) {
        tempObjPerm[subcat.value] = [];
        tempArrIdsToRemove = selectedParentIds.filter((el) => el === subcat.value);
      } else if (subIds.includes(subcat.value)) {
        tempObjPerm[subcat.value] = subcat?.permissions.map((perm) => (perm.id));
      }
    });

    let tempArrPerm = flatten(values(omitBy(tempObjPerm, isEmpty)));
    tempObjSub[parentId] = subIds;

    // update state
    setSelectedParentIds(filter(initialIds, (el) => !tempArrIdsToRemove.includes(el)));
    setSelectedSubcategoryIds(tempObjSub);
    setSelectedPermissionIds(tempObjPerm);

    let selectedDealTypes = filter(initialIds, (el) => !tempArrIdsToRemove.includes(el));

    // update parent component and form
    setPermissionsData({
      selectedDealTypes: compact(concat(selectedDealTypes, subIds)),
      selectedPermission: tempArrPerm
    });
    form.setFieldsValue({
      providers: tempArrPerm,
    });
  }
  // const selectedPersmissionsWithCategoryIds = getPermissionWithIdsList2(
  //   selectedDealTypes,
  //   businessPermissions
  // );
  const updatePermissions = (value) => {
    let tempObjPerm = selectedPermissionIds;

    map(selectedPermissionIds, (perms, idKey) => {
      tempObjPerm[idKey] = perms.filter((perm) => value.includes(perm));
    });

    // update parent component and form
    setPermissionsData({
      selectedPermission: value,
      selectedDealTypes
    });
  };

  let dealTypes = businessPermissions.map((businPerm) => {
    let nameToUse = businPerm.name;
    if (businPerm.name === "Resellers") {
      nameToUse = "Reseller";
    }
    return ({
      label: businPerm.name,
      value: businPerm.id,
      subcategories: map(businPerm.subcategories, (subcat) => {
        return ({
          label: subcat.name,
          value: subcat.id,
          permissions: subcat.permissions
        })
      }),
      disabled: businPerm[`can_select_${replace(nameToUse.toLowerCase(), / /g, "_")}`] && !isEmpty(filter(selectedDealTypes, (el) => {
        let tempObj = find(businessPermissions, { id: el });
        let subNameToUse = tempObj?.name || "";
        if (tempObj?.name === "Resellers") {
          subNameToUse = "Reseller";
        }
        return tempObj && tempObj[`can_select_${replace(subNameToUse.toLowerCase(), / /g, "_")}`] && !tempObj[`can_select_${replace(nameToUse.toLowerCase(), / /g, "_")}`];
      })),
    })
  }
  );

  const { businessId } = useBusinessIdGlobal();
  const { userData } = useUserProfileData(businessId);
  const isSootheMember = userData?.role?.isSootheMember;

  dealTypes = isSootheMember ? dealTypes : filter(dealTypes, (elmt) => (elmt.label !== "Coporate Wellness" && elmt.label !== "Corporate"));

  let selectedParent = get(filter(dealTypes, (elmt) => selectedDealTypes.includes(elmt.value) && !isEmpty(elmt.subcategories)), "0");
  let filteredSubDealTypes = get(selectedParent, "subcategories", []);

  const isGuestBookingSelected = selectedPersmissionsWithCategoryIds.some((el) => el.isGuestBooking);

  return (
    <>
      <div className={styles["wrapper"]}>
        <Row gutter={[0, 16]}>
          {getWrapper(
            "Permission",
            <>
              <Form.Item
                colon={false}
                name="deal-type"
                label="Type of Deal"
                rules={[{ required: true, message: "Deal Type is required" }]}
              >
                <div className={styles["checkbox-group"]}>
                  <Checkbox.Group
                    options={dealTypes}
                    value={selectedParentIds}
                    // TODO maybe use state value instead of props (but prefill if available)
                    onChange={(value) => {
                      if (setHasResellerCategoryInParent) {
                        setHasResellerCategoryInParent(!isEmpty(find(dealTypes, (el) => (includes(value, el.value) && includes(el.label, "Resellers")))))
                      }
                      fillInByParentIds(value);

                      // const valuesToKeep = intersection(
                      //   selectedDealTypes,
                      //   filteredSubDealTypes.map((el) => el.value)
                      // );
                      // const selectedOptions =
                      //   selectedPersmissionsWithCategoryIds.filter((el) =>
                      //     selectedPermission.includes(el.id.id)
                      //   );
                      // const newSelectedPermissions = selectedOptions.filter(
                      //   (perm) => value.includes(perm.categoryId)
                      // );

                      // if (value.length > selectedDealTypes.length) {
                      //   const dealType = value.filter(
                      //     (x) => !selectedDealTypes.includes(x)
                      //   );
                      //   const options = [...selectedOptions.map((el) => el.id)];
                      //   const permOptions = businessPermissions.find(
                      //     (item) => dealType[0] === item.id || item.subcategories.some((subcat) => dealType[0] === subcat.id)
                      //     // TODO correct line above
                      //   );
                      //   if (permOptions) {
                      //     options.push(...permOptions.permissions);
                      //   }
                      //   setPermissionsData({
                      //     selectedDealTypes: [...valuesToKeep, ...value],
                      //     selectedPermission: Array.from(
                      //       new Set([...options.map((opt) => opt.id)])
                      //     ),
                      //   });
                      //   form.setFieldsValue({
                      //     providers: Array.from(
                      //       new Set([...options.map((opt) => opt.id)])
                      //     ),
                      //   });
                      // } else {
                      //   setPermissionsData({
                      //     selectedDealTypes: [...valuesToKeep, ...value],
                      //     selectedPermission: newSelectedPermissions.map(
                      //       (el) => el.id.id
                      //     ),
                      //   });
                      //   form.setFieldsValue({
                      //     providers: newSelectedPermissions.map(
                      //       (el) => el.id.id
                      //     ),
                      //   });
                      // }
                    }}
                  ></Checkbox.Group>
                </div>
              </Form.Item>
              {!isEmpty(filteredSubDealTypes) ? <Form.Item
                colon={false}
                name="sub-deal-type"
                rules={[{ required: true, message: "Subcategory Deal Type is required" }]}
                label={`${selectedParent?.label || "Subcategory"} type (Select all that apply)`}
              >
                <div className={styles["checkbox-group"]}>
                  <Checkbox.Group
                    options={filteredSubDealTypes}
                    value={selectedSubcategoryIds[selectedParent?.value]}
                    onChange={(value) => {
                      adjustBySubcategoryIds(value, filteredSubDealTypes, selectedParent?.value);

                      // const valuesToKeep = intersection(
                      //   selectedDealTypes,
                      //   dealTypes.map((el) => el.value)
                      // );
                      // const selectedOptions =
                      //   selectedPersmissionsWithCategoryIds.filter((el) =>
                      //     selectedPermission.includes(el?.id.id)
                      //   );

                      // const newSelectedPermissions = selectedOptions.filter(
                      //   (perm) => value.includes(perm.categoryId)
                      // );

                      // if (value.length > selectedDealTypes.length) {
                      //   const dealType = value.filter(
                      //     (x) => !selectedDealTypes.includes(x)
                      //   );
                      //   const options = [...selectedOptions.map((el) => el.id)];
                      //   const permOptions = businessPermissions.find(
                      //     (item) => dealType[0] === item.id || item.subcategories.some((subcat) => dealType[0] === subcat.id)
                      //     // TODO correct line above
                      //   );
                      //   if (permOptions) {
                      //     options.push(...permOptions.permissions);
                      //   }
                      //   setPermissionsData({
                      //     selectedDealTypes: [...valuesToKeep, ...value],
                      //     selectedPermission: Array.from(
                      //       new Set([...options.map((opt) => opt.id)])
                      //     ),
                      //   });
                      //   form.setFieldsValue({
                      //     providers: Array.from(
                      //       new Set([...options.map((opt) => opt.id)])
                      //     ),
                      //   });
                      // } else {
                      //   setPermissionsData({
                      //     selectedDealTypes: [...valuesToKeep, ...value],
                      //     selectedPermission: newSelectedPermissions.map(
                      //       (el) => el.id.id
                      //     ),
                      //   });
                      //   form.setFieldsValue({
                      //     providers: newSelectedPermissions.map(
                      //       (el) => el.id.id
                      //     ),
                      //   });
                      // }
                    }}
                  ></Checkbox.Group>
                </div>
              </Form.Item> : null}
              {isGuestBookingSelected && (
                <div style={{ display: "flex" }}>
                  <Form.Item
                    name="business-domain"
                    label="Business Domain"
                    rules={[
                      { required: true, message: "Business domain required" },
                    ]}
                    style={{ maxWidth: "18.75rem", width: "100%" }}
                  >
                    <StyledInput
                      value={businessDomain}
                      onChange={(e) =>
                        setPermissionsData({ businessDomain: e.target.value })
                      }
                      placeholder="Business domain"
                      maxLength={255}
                    />
                  </Form.Item>
                  <span style={{ margin: "45px 0px 0px 10px" }}>
                    .business.soothe.com
                  </span>
                </div>
              )}
              <Form.Item
                colon={false}
                name="providers"
                label="This business may view the following:"
                rules={[
                  { required: true, message: "Permissions are required" },
                ]}
              >
                <Select
                  className={styles["appointment-select"]}
                  placeholder="Enter permissions here"
                  suffixicon={<FeatherIcon icon="chevron-down" size="15px" />}
                  mode="multiple"
                  allowClear
                  showArrow
                  value={selectedPermission}
                  onChange={(value) => {
                    updatePermissions(value);

                    // const selectedOptions =
                    //   selectedPersmissionsWithCategoryIds.filter((el) =>
                    //     value.includes(el.id.id)
                    //   );
                    // const selectedDeals = selectedDealTypes.filter((id) => {
                    //   return selectedOptions.some((element) => {
                    //     return element.categoryId === id;
                    //   });
                    // });
                    // setPermissionsData({
                    //   selectedPermission: value,
                    //   selectedDealTypes: selectedDeals,
                    // });
                    // form.setFieldsValue({
                    //   "deal-type": selectedDeals,
                    // });
                  }}
                >
                  {map(
                    selectedPersmissionsWithCategoryIds,
                    (permission, it) => {
                      return (
                        <Select.Option
                          key={`list-${it}-${permission?.categoryId}-${permission?.id?.id}`}
                          value={permission?.id?.id}
                          className={styles["providers-select"]}
                          style={{ padding: "2px" }}
                        >
                          <span> {permission?.id?.perm_description}</span>
                        </Select.Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </>
          )}
        </Row>
      </div>
    </>
  );
};

export default (props) => {
  const { businessPermissions, isLoading } = useGetBusinessPermissions();
  if (!businessPermissions || isLoading) {
    return <Skeleton active />;
  }
  return <Permissions {...props} businessPermissions={businessPermissions} />;
};
