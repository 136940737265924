/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import Row from "antd/es/row";
import "antd/es/row/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";
import DiscountTable from "./DiscountTable";
import Card from "antd/es/card";
import "antd/es/card/style";
import Heading from "../../../atoms/heading/heading";
import Col from "antd/es/col";
import "antd/es/col/style";
import Form from "antd/es/form";
import "antd/es/form/style";
import Radio from "antd/es/radio";
import "antd/es/radio/style";
import styles from "./Discount.module.scss";
import Space from "antd/es/space";
import "antd/es/space/style";
import Typography from "antd/es/typography";
import "antd/es/typography/style";
import useGetDiscounts from "../../../../utilities/hooks/useGetDiscounts";
import useGetPropertyDiscounts from "../../../../utilities/hooks/useGetPropertyDiscounts";
import openNotification from "../../../../utilities/openNotification";
import { Tooltip } from "antd";
import FeatherIcon from "feather-icons-react";

const { Text } = Typography;

const discountLevels = [
  { label: "By Business", value: "byBusiness" },
  { label: "By Property", value: "byProperty" },
];

function Discount({
  form,
  isLoading,
  discount,
  setDiscount,
  type = "add",
  mode,
  discounts,
  hasResellerCategory,
}) {
  const { discountType } = discount;

  useEffect(() => {
    if (discounts) {
      const discountMapping = {};
      for (const key in discounts) {
        if (
          discounts[key].discount_amount === 0 &&
          discounts[key].discount_percentage === 0
        ) {
          discountMapping[key] = {
            type: "amount",
            value: 0,
            id: discounts[key].product_id,
          };
          continue;
        }
        if (discounts[key].discount_amount === 0) {
          discountMapping[key] = {
            type: "percentage",
            value: discounts[key].discount_percentage,
            id: discounts[key].product_id,
          };
          continue;
        }
        if (discounts[key].discount_percentage === 0) {
          discountMapping[key] = {
            type: "amount",
            value: discounts[key].discount_amount,
            id: discounts[key].product_id,
          };
        }
      }

      setDiscount({ services: Object.entries(discountMapping) });
    }
  }, [discounts]);

  form.setFieldsValue({
    [`discount-type`]: discountType,
  });
  const showNotfifcation = (type) => {
    if (type === "byBusiness") {
      openNotification(
        `Changing from Property-level settings to Business-level settings will
       delete all individual selections previously made in each property. 
       This action cannot be undone`
      );
    }
    if (type === "byProperty") {
      openNotification(
        `Changing from Business-level settings to Property-level settings will allow you to make selection
       for each property, overriding the Business level settings previously set. To change this, 
       you will need to go back to settings.`
      );
    }
  };
  const onDiscountTypeChange = (event, discountType) => {
    const value = event.target.value;
    setDiscount({ discountType: value });
    if (mode === "edit") {
      showNotfifcation(value);
    }
  };
  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div className={styles["wrapper"]}>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <div className="notification-box-single">
                <Card>
                  <div
                    className="notification-header"
                    style={{
                      height: "50px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Heading className="notification-header__text" as="h4">
                      <div className=" flex-spaced" style={{ justifyContent: "start" }}>
                        Appointment Discounts <Tooltip placement="bottom" className="ml-8" overlayInnerStyle={{
                          borderRadius: "10px",
                          color: "white"
                        }}
                          title="Discounts applied to individual appointments." color="#252C3F"><FeatherIcon size="20" icon="info" /></Tooltip>
                      </div>
                    </Heading>
                  </div>
                  <div className="notification-body">
                    <div>
                      {type === "business" && !hasResellerCategory && (
                        <Form.Item
                          colon={false}
                          name="discount-type"
                          label="Discount Type"
                          rules={[
                            {
                              required: true,
                              message: "Session time is required",
                            },
                          ]}
                        >
                          <Radio.Group
                            className={`${styles["taps-container"]} mb-200`}
                            onChange={(e) =>
                              onDiscountTypeChange(e, discountType)
                            }
                            value={discountType}
                          >
                            {discountLevels.map((type, index) => (
                              <Radio.Button
                                key={index}
                                value={type.value}
                                className={styles["discount-tap"]}
                              >
                                {type.label}
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      )}
                      {discountType === "byProperty" && !hasResellerCategory ? (
                        <Space direction="horizontal" className="mb-20">
                          <Text type="secondary">
                            When you set up a property, you will be able to
                            input your discount preferences.
                          </Text>
                        </Space>
                      ) : (
                        <DiscountTable
                          form={form}
                          discountServices={discount.services}
                          setDiscount={setDiscount}
                        />
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default (props) => {
  const {
    discounts: businessDiscounts,
    isLoading: isBusinessDiscountsLoading,
  } = useGetDiscounts(props.businessId);
  const {
    discounts: propertyDiscounts,
    isLoading: isPropertyDiscountsLoading,
  } = useGetPropertyDiscounts(props.locationId);

  if (isBusinessDiscountsLoading || isPropertyDiscountsLoading) {
    return <Skeleton active />;
  }
  return (
    <Discount {...props} discounts={businessDiscounts || propertyDiscounts} />
  );
};
