import React, { useEffect, useReducer, useState } from "react";
import FeatherIcon from "feather-icons-react";
import moment from "moment-timezone";
import Form from "antd/es/form";
import "antd/es/form/style";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Card from "antd/es/card";
import "antd/es/card/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";
import TimePicker from "antd/es/time-picker";
import "antd/es/time-picker/style";
import DatePicker from "antd/es/date-picker";
import "antd/es/date-picker/style";
import styles from "./RescheduleAppointmentModal.module.scss";

import { Modal } from "../../atoms/modals/antd-modals";
import { Button } from "../../atoms/buttons/buttons";
import { FlexRow } from "../../atoms";
import { onTimeSelect } from "../../../utilities/helpers";
import openNotification from "../../../utilities/openNotification";
import { useQueryClient } from "react-query";
import appointmentsAPI from "../../../utilities/api/appointment";
import useGetAppointmentsDetails from "../../../utilities/hooks/useGetAppointmentDetails";
import useGetLocation from "../../../utilities/hooks/useGetLocation";
import { get, isEmpty } from "lodash";
import FeeModal from "../viewShift/FeeModal";
import { ADDED_MARGIN_H, DEFAULT_WORKING_HOURS_BY_DAY } from "../../../utilities/constants";

function RescheduleAppointmentModal({
  isOpen,
  onExit,
  setLoading,
  loading,
  appointmentId,
  locationId,
  businessId,
}) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [rescheduleSpaAppointment, setRescheduleSpaAppointment] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      isFeeModalOpen: false,
      isFeeModalLoading: false,
    }
  );

  const {
    data: selectedAppointment,
    isLoading,
    refetch,
    should_show_warning,
    able_to_adjust
  } = useGetAppointmentsDetails({
    appointmentId,
  });
  const {
    location,
    workingHours,
    isLoading: isLocationLoading,
  } = useGetLocation(locationId, businessId);

  const [time, setTime] = useState();
  const [selectedDay, setSelectedDay] = useState(false);
  const [workingDaysAndHours, setWorkingDaysAndHours] = useState({});
  const onDateSelect = (date) => {
    setSelectedDay({ day: moment(date).format("dddd"), date });
    form.resetFields(["time"]);
  };
  const timeFormat = "hh:mm:ss";
  const dateFormat = "YYYY-MM-DD";


  useEffect(() => {
    const isReseller = get(selectedAppointment, "b2b_type", "") === "reseller_concierge";
    if (isReseller) {
      setWorkingDaysAndHours(DEFAULT_WORKING_HOURS_BY_DAY);
    } else if (!isEmpty(workingHours)) {
      let workingDaysAndHours = {};
      workingHours
        ?.filter((day) => day.enabled)
        .forEach((day) => {
          workingDaysAndHours[day.weekname] = {
            start: day.from,
            end: day.till,
          };
        });
      setWorkingDaysAndHours(workingDaysAndHours);
    }
  }, [workingHours, selectedAppointment]);
  const preRescheduleStep = (form) => {
    form.validateFields().then((values) => {
      setLoading(true);
      let data = {
        id: selectedAppointment.appointment_number,
        business_id: selectedAppointment.business_id,
        location_id: selectedAppointment.location_id,
        session_date: values.date.format("YYYY-MM-DD"),
        session_time: values.time.format("HH:mm"),
        rebook: "",
        quoted_fee: selectedAppointment.reschedule_fee,
        fee_reason: selectedAppointment.reschedule_fee_reason,
      };

      if (selectedAppointment.reschedule_fee_reason) {
        setRescheduleSpaAppointment({
          isFeeModalOpen: true,
          isFeeModalLoading: false,
          data,
          form,
        });
      } else {
        onReschedule(data);
      }
    });
  }
  const onReschedule = (data) => {
    appointmentsAPI
      .rescheduleAppointment(data)
      .then((res) => {
        setLoading(false);
        refetch();
        queryClient.refetchQueries("appointments");
        queryClient.refetchQueries("shifts");
        queryClient.refetchQueries("businessAppointments");
        queryClient.refetchQueries("allBusinessesAppointments");
        openNotification(
          "Reschedule Appointment",
          res.data.message,
          "success"
        );
        onExit();
      })
      .catch(({ response }) => {
        setLoading(false);
        openNotification(
          "Error",
          response?.data?.data[0]?.message || response.data.message,
          "error"
        );
      });
  };
  const checkDisabledDate = (current) => {
    const availbleHours =
      workingDaysAndHours[current.format("dddd")];
    return (
      (moment(current.format("YYYY-MM-DD")).isSame(
        moment().format("YYYY-MM-DD")
      ) &&
        moment().hour() + ADDED_MARGIN_H >=
        moment(availbleHours?.end, "hh:mm:ss").hour()) ||
      moment().add(-1, "days") >= current ||
      !Object.keys(workingDaysAndHours).includes(
        String(current.format("dddd"))
      )
    );
  }

  return (<>
    <Modal
      wrapClassName={styles["modal-container"]}
      title={
        !isLoading &&
        !isLocationLoading &&
        `When would you like to reschedule Appointment #${selectedAppointment?.appointment_number} to?`
      }
      visible={isOpen && !rescheduleSpaAppointment.isFeeModalOpen}
      footer={null}
      onCancel={onExit}
      centered
    >
      {isLoading || isLocationLoading ? (
        <Skeleton active paragraph={{ rows: 11 }} />
      ) : (
        <div className="view-modal-body">
          <div className="view-modal-body-card" style={{ marginRight: 0 }}>
            <Card
              style={{
                backgroundColor:
                  selectedAppointment.status === "pending" &&
                    selectedAppointment.needs_attention
                    ? "#FFEFED"
                    : "#FAFBFF",
                color:
                  selectedAppointment.status === "pending" &&
                    selectedAppointment.needs_attention
                    ? "#C11500"
                    : "#272B41",
                marginBottom: "32px",
                border: "none",
              }}
              headless="true"
            >
              <p
                className="view-modal_section-title"
                style={{
                  color:
                    selectedAppointment.status === "pending" &&
                      selectedAppointment.needs_attention
                      ? "#C11500"
                      : "#586B94",
                }}
              >
                Current Appointment
              </p>

              <FlexRow>
                <FeatherIcon
                  icon="calendar"
                  size={16}
                  style={{
                    color:
                      selectedAppointment.status === "pending" &&
                        selectedAppointment.needs_attention
                        ? "#C11500"
                        : "#586B94",
                  }}
                  className="mr-10"
                />
                <p>
                  {moment(selectedAppointment.time.utc, "YYYY/MM/DD").format(
                    "dddd, MMM, D"
                  )}{" "}
                  at {selectedAppointment.time.display}
                </p>
              </FlexRow>
              <FlexRow>
                <FeatherIcon
                  icon="map-pin"
                  size={16}
                  style={{
                    color:
                      selectedAppointment.status === "pending" &&
                        selectedAppointment.needs_attention
                        ? "#C11500"
                        : "#586B94",
                  }}
                  className="mr-10"
                />
                <p>
                  {`${selectedAppointment.address.address_line_1}, ${selectedAppointment.address.city}`}
                  {selectedAppointment.address.hotel_room_number &&
                    ` • Room ${selectedAppointment.address.hotel_room_number}`}
                </p>
              </FlexRow>
              <FlexRow>
                <FeatherIcon
                  icon="shopping-cart"
                  size={16}
                  style={{
                    color:
                      selectedAppointment.status === "pending" &&
                        selectedAppointment.needs_attention
                        ? "#C11500"
                        : "#586B94",
                  }}
                  className="mr-10"
                />
                <p>
                  {selectedAppointment.cart_products[0].service_type}
                  {selectedAppointment.cart_products[0].provider_name &&
                    ` with ${selectedAppointment.cart_products[0].provider_name}`}
                </p>
              </FlexRow>
              {selectedAppointment.booked_by && (
                <FlexRow>
                  <FeatherIcon
                    icon="user"
                    size={16}
                    style={{
                      color:
                        selectedAppointment.status === "pending" &&
                          selectedAppointment.needs_attention
                          ? "#C11500"
                          : "#586B94",
                    }}
                    className="mr-10"
                  />
                  <p>
                    Booked by{" "}
                    {selectedAppointment.booked_by} on{" "}
                    {moment(selectedAppointment.time.utc, "YYYY/MM/DD").format("MM/DD/YY")}
                  </p>
                </FlexRow>
              )}
            </Card>
          </div>
          <div>
            <Form
              form={form}
              name="reasons"
              onFinish={() => preRescheduleStep(form)}
              layout="vertical"
            >
              <Row gutter={24}>
                <Col xxl={12} lg={12} md={12} xs={24}>
                  <Form.Item
                    colon={false}
                    name="date"
                    label="Date"
                    rules={[{ required: true, message: "Date is required!" }]}
                  >
                    <DatePicker
                      className={styles["select_handler"]}
                      suffixIcon={
                        <FeatherIcon
                          icon="calendar"
                          size={16}
                          color="#586B94"
                        />
                      }
                      allowClear={false}
                      onSelect={onDateSelect}
                      disabled={isEmpty(workingDaysAndHours)}
                      disabledDate={checkDisabledDate}
                    />
                  </Form.Item>
                </Col>
                <Col xxl={12} lg={12} md={12} xs={24}>
                  <Form.Item
                    colon={false}
                    name="time"
                    label="Time"
                    rules={[{ required: true, message: "time is required!" }]}
                  >
                    <TimePicker
                      className={styles["select_handler"]}
                      suffixIcon={
                        <FeatherIcon icon="clock" size={16} color="#586B94" />
                      }
                      popupClassName={styles["time-picker-popup"]}
                      use12Hours
                      allowClear={false}
                      disabled={!selectedDay.day}
                      disabledTime={(now) => {
                        const disabledHours = () => {
                          let hours = [];
                          const availbleHours =
                            workingDaysAndHours[selectedDay.day];
                          if (!availbleHours) return;
                          for (
                            let i = 0;
                            i < moment(availbleHours.start, "hh:mm:ss").hour();
                            i++
                          ) {
                            hours.push(i);
                          }
                          for (
                            let i =
                              moment(availbleHours.end, "hh:mm:ss").hour() + 1;
                            i <= 24;
                            // TODO recheck
                            i++
                          ) {
                            hours.push(i);
                          }
                          if (selectedDay?.date?.diff(moment(), "hours") === 0) {
                            for (
                              let i = moment(
                                availbleHours.start,
                                "hh:mm:ss"
                              ).hour();
                              i <= moment(availbleHours.end, "hh:mm:ss").hour();
                              i++
                            ) {
                              if (i < moment().hours() + ADDED_MARGIN_H) {
                                hours.push(i);
                              }
                              if (
                                i === moment().hours() + ADDED_MARGIN_H &&
                                moment().minutes() > 57
                              ) {
                                hours.push(i);
                              }
                            }
                          }
                          return hours;
                        };

                        const disabledMinutes = (selectedHour) => {
                          const availbleHours =
                            workingDaysAndHours[selectedDay.day];
                          if (!availbleHours) return;
                          let minutes = [];
                          if (
                            selectedHour < 0 ||
                            selectedHour ===
                            moment(availbleHours.end, "hh:mm:ss").hour()
                          ) {
                            for (
                              let i =
                                selectedHour ===
                                  moment(availbleHours.end, "hh:mm:ss").hour()
                                  ? 1
                                  : 0;
                              i < 60;
                              i++
                            ) {
                              minutes.push(i);
                            }
                          }

                          if (selectedHour === moment().hour() + ADDED_MARGIN_H) {
                            for (let i = 0; i < moment().minute(); i++) {
                              minutes.push(i);
                            }
                          }

                          return minutes;
                        };

                        return {
                          disabledHours,
                          disabledMinutes
                        };
                      }}
                      minuteStep={5}
                      inputReadOnly
                      format="h:mm A"
                      value={moment(time, "hh:mm A")}
                      onSelect={(time) =>
                        onTimeSelect(
                          time,
                          form,
                          setTime,
                          selectedDay,
                          workingDaysAndHours,
                          dateFormat,
                          timeFormat
                        )
                      }
                      showNow={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <footer>
            <Button key={1} size="small" onClick={onExit} type="light">
              Back
            </Button>

            <Button
              style={{ margin: 0 }}
              size="small"
              type="sootheButton"
              onClick={() => form.submit()}
              key="submit"
              htmlType="submit"
              loading={loading}
            >
              Reschedule now
            </Button>
          </footer>
        </div>
      )}
    </Modal>
    {rescheduleSpaAppointment.isFeeModalOpen && (
      <FeeModal
        isOpen={rescheduleSpaAppointment.isFeeModalOpen}
        loading={rescheduleSpaAppointment.isFeeModalLoading}
        title={"Are you sure you want to reschedule?"}
        body={
          <div>
            <p>
              Rescheduling will put the shift in a pending state until the selected Providers accept the new changes.
            </p>
            <p>You're {get(selectedAppointment, "reschedule_fee_reason", "")}</p>
            <p>
              <span style={{ fontWeight: 500 }}>You will be charged {selectedAppointment.reschedule_fee} for rescheduling this appointment.</span> Are you sure you want to reschedule this appointment?
            </p>
          </div>
        }
        action="reschedule"
        onExit={onExit}
        onSubmit={() => {
          setRescheduleSpaAppointment({
            isFeeModalLoading: true,
          });
          onReschedule(rescheduleSpaAppointment.data);
        }}
      />
    )}
  </>
  );
}

export default RescheduleAppointmentModal;
