/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Form from "antd/es/form";
import "antd/es/form/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";
import Typography from "antd/es/typography";
import "antd/es/typography/style";
import Space from "antd/es/space";
import "antd/es/space/style";
import Card from "antd/es/card";
import "antd/es/card/style";
import Radio from "antd/es/radio";
import "antd/es/radio/style";

import Heading from "../../../atoms/heading/heading";
import styles from "./Billing.module.scss";
import useGetBusinessBilling from "../../../../utilities/hooks/useGetBusinessBilling";
import useGetPropertyBilling from "../../../../utilities/hooks/useGetPropertyBilling";
import validator from "validator";
import openNotification from "../../../../utilities/openNotification";
import { LabeledInput } from "../../../molecules";
import { compact, isArray, isEmpty, join } from "lodash";
import AddressAutoComplete from "../../../atoms/inputs/AddressAutoComplete";

const { Text } = Typography;

const Billing = (props) => {
  const {
    billingData: {
      billingType,
      emails,
      due_in_days,
      address,
    },
    setBillingData,
    type = "business",
    mode,
    billing,
    form,
    hasResellerCategory,
  } = props;

  useEffect(() => {
    if (billing) {
      const { email, emails, due_in_days, address } = billing;
      let tempEmails = billingType === "byProperty" ? emails : email;
      if (!isArray(tempEmails)) {
        tempEmails = [tempEmails];
      }
      setBillingData({
        billingType,
        due_in_days: due_in_days || 15,
        emails: tempEmails,
      });
      form.setFieldsValue({
        "billing-type": billingType,
        "payment-terms": due_in_days || 15,
        "billing-emails": join(tempEmails, ","),
        "billing-address": address?.address_line_1 || "",
      });
    }
  }, [billing]);

  const discountLevels = [
    { label: "By Business", value: "byBusiness" },
    { label: "By Property", value: "byProperty" },
  ];

  useEffect(() => {
    let tempEmails = emails || "";
    if (!isArray(tempEmails)) {
      tempEmails = [tempEmails];
    }
    props.form.setFieldsValue({
      [`billing-type`]: billingType,
      [`payment-terms`]: due_in_days || 15,
      [`billing-emails`]: join(tempEmails, ","),
      [`billing-address`]: address?.address_line_1 || "",
    });
  }, []);

  const getWrapper = (title, children) => {
    return (
      <div className="card-wrapper">
        <Card>
          <div
            className="header"
            style={{
              height: "50px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Heading className="header__text" as="h4">
              {title}
            </Heading>
          </div>
          <div className="body">
            <Card>{children}</Card>
          </div>
        </Card>
      </div>
    );
  };
  const showNotfifcation = (type) => {
    if (type === "byBusiness") {
      openNotification(
        `Changing from Property-level settings to Business-level settings will
       delete all individual selections previously made in each property. 
       This action cannot be undone.`
      );
    }
    if (type === "byProperty") {
      openNotification(
        `Changing from Business-level settings to Property-level settings will allow you to make selection
       for each property, overriding the Business level settings previously set. To change this, 
       you will need to go back to settings.`
      );
    }
  };
  const onBillingTypeChange = (e) => {
    const value = e.target.value;
    setBillingData({ billingType: value });
    if (mode === "edit") {
      showNotfifcation(value);
    }
  };
  const onPaymentTermsChange = (e) => {
    const value = e.target.value;
    setBillingData({
      due_in_days: value
    });
  };

  return (
    <>
      <div className={styles["wrapper"]}>
        <Row gutter={[0, 16]}>
          {getWrapper(
            "Payments & Billing",
            <>
              {type === "business" && !hasResellerCategory && (
                <Form.Item
                  colon={false}
                  name="billing-type"
                  label="Billing Type"
                  rules={[
                    {
                      required: true,
                      message: "Billing type is required",
                    },
                  ]}
                >
                  <Radio.Group
                    className={styles["taps-container"]}
                    value={billingType}
                    onChange={(e) => onBillingTypeChange(e, billingType)}
                    defaultValue={billingType}
                  >
                    {discountLevels.map((type, index) => (
                      <Radio.Button
                        id={`billing_${type.value}`}
                        key={index}
                        value={type.value}
                        sessionType={type.title}
                        className={styles["discount-tap"]}
                      >
                        {type.label}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              )}

              {billingType === "byProperty" && !hasResellerCategory ? (
                <Space direction="horizontal" className="mb-20">
                  <Text type="secondary">
                    When you set up a Property, you will be able to input your
                    billing information.
                  </Text>
                </Space>
              ) : (
                <>
                  <div className={styles["title"]}>Finance Contacts</div>
                  <Row gutter={[16, 0]} justify="space-between">
                    <Col xs={24} sm={24} lg={24}>
                      <LabeledInput
                        value={join(emails, ",")}
                        // id="billing-emails"
                        placeholder="Email"
                        name="billing-emails"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: "Billing contact email required",
                          }
                        ]}
                        extraRules={[
                          () => ({
                            validator(_, value) {
                              let values = value && compact(value.toLowerCase().replace(/\s+/g, "").split(","));
                              let isValid = !isEmpty(values) && values.every((email) => validator.isEmail(email));
                              if (!value || isValid) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Please enter one or more valid email addresses, separated by commas")
                              );
                            },
                          })
                        ]}
                        onChange={(e) => {
                          setBillingData({
                            emails: compact(e.target.value
                              .toLowerCase()
                              .replace(/\s+/g, "")
                              .split(",")),
                          })
                        }
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}
              {billingType === "byProperty" && !hasResellerCategory ? null : <>
                <Form.Item
                  colon={false}
                  name="billing-address"
                  label="Billing Address"
                  rules={[
                    {
                      required: true,
                      message: "Billing address is required",
                    },
                  ]}
                >
                  <AddressAutoComplete
                    onSelectAddress={(newAddress) => {
                      const {
                        addressLine1: address_line_1,
                        city,
                        state,
                        zipCode: zip_code,
                        country,
                      } = newAddress;
                      setBillingData({
                        address: {
                          address_line_1,
                          city,
                          state,
                          zip_code,
                          country,
                        },
                      });
                      form.setFieldsValue({
                        "billing-address": address_line_1,
                      });
                    }}
                    additionClassName="p-14-10 br-light"
                  />
                </Form.Item>
              </>
              }
              {billingType === "byProperty" && !hasResellerCategory ? null : <>
                <div className={"mb-30"} />
                <Form.Item
                  colon={false}
                  name="payment-terms"
                  label="Payment Terms"
                >
                  <div style={{ fontSize: "14px", lineHeight: "20px", marginBottom: "16px" }}>Select monthly invoice due date.</div>
                  <Radio.Group
                    className={styles["taps-container"]}
                    value={due_in_days}
                    onChange={(e) => onPaymentTermsChange(e)}
                  >
                    <Radio.Button
                      value={15}
                      className={styles["discount-tap"]}
                    >15 days</Radio.Button>
                    <Radio.Button
                      value={30}
                      className={styles["discount-tap"]}
                    >30 days</Radio.Button>
                    <Radio.Button
                      value={45}
                      className={styles["discount-tap"]}
                    >45 days</Radio.Button>
                    <Radio.Button
                      value={60}
                      className={styles["discount-tap"]}
                    >60 days</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </>}
            </>
          )}
        </Row>
      </div>
    </>
  );
};

export default (props) => {
  const {
    businessId,
    status,
    billingData: { billingType },
  } = props;

  const { billing: businessBilling, isLoading: isBusinessBillingLoading } =
    useGetBusinessBilling({
      id: businessId,
      status,
      type: billingType,
    });
  const { billing: propertyBilling, isLoading: isPropertyBillingLoading } =
    useGetPropertyBilling(props.locationId);

  if (isBusinessBillingLoading || isPropertyBillingLoading) {
    return <Skeleton active />;
  }

  return <Billing {...props} billing={businessBilling || propertyBilling} />;
};
