import React from "react";
import AutoCompleteInput from "react-google-autocomplete";
import findIndex from "lodash/findIndex";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import keys from "lodash/keys";
import "./AddressAutoComplete.scss";
import openNotification from "../../../utilities/openNotification";

const Autocomplete = ({ onSelectAddress, value, disabled = false, additionClassName = '' }) => {
  const keysConfig = {
    postal_code: {
      fieldName: "zipCode",
      valueToRead: "short_name",
    },
    country: {
      fieldName: "country",
      valueToRead: "short_name",
    },
    administrative_area_level_1: {
      fieldName: "state",
      valueToRead: "short_name",
    },
    administrative_area_level_2: {
      fieldName: "county",
      valueToRead: "long_name",
    },
    administrative_area_level_4: {
      fieldName: "township",
      valueToRead: "long_name",
    },
    locality: {
      fieldName: "city",
      valueToRead: "long_name",
    },
  };

  const addressAdditionalDetails = [
    "street_number",
    "neighborhood",
    "route",
    "sublocality",
  ];

  const cKeys = keys(keysConfig);

  const formatStr = (txt, separator = "", addedStr = "") => {
    if (txt) {
      return txt + separator + addedStr;
    } else {
      return addedStr;
    }
  };

  const getFormattedAddress = (address_components) => {
    let formattedAddress = {
      name: "",
      addressLine1: "",
    };
    forEach(address_components, (comp) => {
      let currTypes = comp.types;

      if (!isEmpty(intersection(currTypes, cKeys))) {
        let conf = keysConfig[currTypes[0]];
        formattedAddress[conf.fieldName] = comp[conf.valueToRead];
      } else if (!isEmpty(intersection(currTypes, addressAdditionalDetails))) {
        let isStreetInfo = intersection(currTypes, ["street_number", "route"]);
        let skipSeparator = !isEmpty(isStreetInfo);
        let separationChar = skipSeparator ? " " : ", ";
        formattedAddress["name"] = formatStr(
          formattedAddress["name"],
          separationChar,
          comp["short_name"]
        );
        formattedAddress["addressLine1"] = formatStr(
          formattedAddress["addressLine1"],
          separationChar,
          comp["long_name"]
        );
      }
    });

    return formattedAddress;
  };

  const handleAddressSelect = (place) => {
    let address_components = place && place.address_components;
    let fullAddress;
    if (
      isEmpty(address_components) ||
      findIndex(address_components, (el) =>
        includes(el.types, "street_number")
      ) === -1
    ) {
      openNotification(
        "Save",
        "The selected address is not valid. Please select another one with a street number.",
        "error"
      );
      return;
    }
    fullAddress = getFormattedAddress(address_components);
    fullAddress.formattedAddress = place.formatted_address;
    onSelectAddress(fullAddress);
  };

  return (
    <AutoCompleteInput
      placeholder="Enter address"
      apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
      onPlaceSelected={handleAddressSelect}
      options={{
        types: ["geocode", "establishment"],
      }}
      defaultValue={value}
      className={`arrival-address-autocomplete ${additionClassName}`}
      disabled={disabled}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault(); // Prevent form submission
        }
      }}
    />
  );
};

export default Autocomplete;
