import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import moment from "moment-timezone";
import Form from "antd/es/form";
import "antd/es/form/style";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Card from "antd/es/card";
import "antd/es/card/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";
import TimePicker from "antd/es/time-picker";
import "antd/es/time-picker/style";
import DatePicker from "antd/es/date-picker";
import "antd/es/date-picker/style";
import styles from "./RescheduleAppointmentModal.module.scss";

import { Modal } from "../../atoms/modals/antd-modals";
import { Button } from "../../atoms/buttons/buttons";
import { FlexRow } from "../../atoms";
import openNotification from "../../../utilities/openNotification";
import { useQueryClient } from "react-query";
import appointmentsAPI from "../../../utilities/api/appointment";
import useGetAppointmentsDetails from "../../../utilities/hooks/useGetAppointmentDetails";
import useGetLocation from "../../../utilities/hooks/useGetLocation";
import { get, isEmpty } from "lodash";
import { fromDisabledTime, lastDisabledTime, onSelectHourFrom, onSelectHourTill } from "../../organisms/CreateShift/TimeAndDate/helpers";
import WarningModal from "../../organisms/CreateShift/WarningModal";
import { getMinutes } from "../../../utilities/helpers";
import { ADDED_MARGIN_H, DEFAULT_START_HOUR, DEFAULT_WORKING_HOURS_BY_DAY, FIXED_DISABLED_HOURS } from "../../../utilities/constants";

function ModifyAppointmentModal({
  isOpen,
  onExit,
  setLoading,
  loading,
  appointmentId,
  locationId,
  businessId,
  isSootheMember
}) {
  let fixedDisabledHours = FIXED_DISABLED_HOURS;
  const [firstDisabledHours, setFirstDisabledHours] = useState([]);
  const [lastDisabledHours, setLastDisabledHours] = useState([]);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const {
    data: selectedAppointment,
    isLoading,
    refetch,
    should_show_warning,
  } = useGetAppointmentsDetails({
    appointmentId,
  });

  const {
    workingHours,
    isLoading: isLocationLoading,
  } = useGetLocation(locationId, businessId);

  const [startTime, setStartTime] = useState(moment(get(selectedAppointment, "time.utc", "")).tz(get(selectedAppointment, "time.timezone", "")));
  const [endTime, setEndTime] = useState(moment(get(selectedAppointment, "cart_end-time.utc", "")).tz(get(selectedAppointment, "cart_end-time.timezone", "")));
  const [selectedDay, setSelectedDay] = useState({
    day: moment(selectedAppointment?.time?.utc).format("dddd"),
    date: selectedAppointment?.time?.utc && moment(selectedAppointment?.time?.utc)
  });
  const [availableShiftTime, setAvailableShiftTime] = useState({
    start: undefined,
    end: undefined,
  });
  const [workingDaysAndHours, setWorkingDaysAndHours] = useState({});

  const handleSetShiftHours = ({ date, start, end, shiftHours, setShiftHours }) => {
    const formattedDate = date.format(dateFormat);
    const firstAppointmentAvailableHour = isSootheMember
      ? date
      : date.add(ADDED_MARGIN_H, "hours");
    const firstAppointmentEnabledHour = moment(start, timeFormat);
    const lastAppointmentEnabledHour = moment(end, timeFormat);
    const currentShiftHoursStart =
      shiftHours.start !== 0 ? shiftHours.start?.hours() : shiftHours.start;
    const currentShiftHoursEnd =
      shiftHours.end !== 0 ? shiftHours.end?.hours() : shiftHours.end;
    if (moment().format(dateFormat) === date.format(dateFormat)) {
      if (
        firstAppointmentAvailableHour.hour() <
        firstAppointmentEnabledHour.hour()
      ) {
        setShiftHours({
          shiftHours: {
            ...shiftHours,
            start: moment().set({
              hours: firstAppointmentEnabledHour.hour(),
              minutes: firstAppointmentEnabledHour.minutes(),
            }),
            end:
              firstAppointmentAvailableHour >= currentShiftHoursEnd
                ? null
                : shiftHours.end,
          },
        });
        return;
      }
      if (
        firstAppointmentAvailableHour.hour() > lastAppointmentEnabledHour.hour()
      ) {
        setShiftHours({
          shiftHours: {
            ...shiftHours,
            start: moment().set({
              hours: lastAppointmentEnabledHour.hour() - 1,
              minutes: 0,
            }),
            end:
              firstAppointmentAvailableHour >= currentShiftHoursEnd
                ? null
                : shiftHours.end,
          },
        });
        return;
      }
      let firstAptAvailableTime = firstAppointmentAvailableHour;
      let hours = firstAptAvailableTime.hour();
      let minutes = getMinutes(firstAptAvailableTime.minutes());
      setShiftHours({
        shiftHours: {
          ...shiftHours,
          start: moment(date || "").set({
            hours: hours,
            minutes: minutes,
            seconds: 0,
          }),
          end:
            firstAppointmentAvailableHour >= currentShiftHoursEnd
              ? null
              : shiftHours.end,
        },
      });
      form.setFieldsValue({
        "start-time": moment(date || "").set({
          hours: hours,
          minutes: minutes,
          seconds: 0,
        }),
        "end-time":
          firstAppointmentAvailableHour >= currentShiftHoursEnd
            ? null
            : shiftHours.end,
      });
      return;
    } else {
      if (
        !(currentShiftHoursStart >= moment(`${formattedDate} ${start}`, `${dateFormat} ${timeFormat}`).hours()) &&
        !(currentShiftHoursEnd <= moment(`${formattedDate} ${end}`, `${dateFormat} ${timeFormat}`).hours())
      ) {
        setShiftHours({
          shiftHours: {
            ...shiftHours,
            start: moment(`${formattedDate} ${start}`, `${dateFormat} ${"hh:mm"}`),
          },
        });
        form.setFieldsValue({
          "start-time": moment(`${formattedDate} ${shiftHours.start}`, `${dateFormat} ${"hh:mm"}`),
          "end-time": null,
        });
      } else {
        if (currentShiftHoursStart <= moment(`${formattedDate} ${start}`, `${dateFormat} ${timeFormat}`).hours()) {
          setShiftHours({
            shiftHours: {
              ...shiftHours,
              start: moment(`${formattedDate} ${start}`, `${dateFormat} ${timeFormat}`),
              end:
                moment(`${formattedDate} ${start}`, `${dateFormat} ${timeFormat}`).hours() >= currentShiftHoursEnd
                  ? null
                  : shiftHours.end,
            },
          });
          form.setFieldsValue({
            "start-time": moment(`${formattedDate} ${start}`, `${dateFormat} ${timeFormat}`),
            "end-time":
              moment(`${formattedDate} ${start}`, `${dateFormat} ${timeFormat}`).hours() >= currentShiftHoursEnd
                ? null
                : shiftHours.end,
          });
        }
        if (currentShiftHoursEnd >= moment(`${formattedDate} ${end}`, `${dateFormat} ${timeFormat}`).hours()) {
          setShiftHours({
            shiftHours: {
              ...shiftHours,
              end: moment(`${formattedDate} ${end}`, `${dateFormat} ${timeFormat}`),
            },
          });
          form.setFieldsValue({
            "end-time": moment(`${formattedDate} ${end}`, `${dateFormat} ${timeFormat}`),
          });
        }
      }
    }
  };
  const onDateSelect = (date) => {
    // setSelectedDay({ day: moment(date).format("dddd"), date });
    // form.resetFields(["start-time", "end-time"]);
    const day = moment(date).format("dddd");
    const { start, end } = workingDaysAndHours[day];
    setSelectedDay({ day, date });
    setAvailableShiftTime({ start, end });
    handleSetShiftHours({
      date, start, end, shiftHours: { start: startTime, end: endTime }, setShiftHours: ({ shiftHours }) => {
        let { start, end } = shiftHours;
        setStartTime(start || startTime);
        setEndTime(end || endTime);
        form.setFieldsValue({
          "end-time": end || endTime,
          "start-time": start || startTime
        });
      }
    })
  };
  const timeFormat = "HH:mm:ss";
  const dateFormat = "YYYY-MM-DD";
  // useEffect(() => {
  //   form.setFieldsValue({
  //     date: selectedDay?.date,
  //     "start-time": startTime,
  //     "end-time": endTime
  //   });
  // }, [selectedAppointment])
  // todo recheck copied code from timeanddate in create shift form
  useEffect(() => {
    const day = selectedDay?.day;
    if (workingDaysAndHours[day] && selectedDay?.date && startTime) {
      const { start, end } = workingDaysAndHours[day];
      setAvailableShiftTime({
        start: moment(start, "hh:mm"),
        end: moment(end, "hh:mm"),
      });
      if (
        (startTime !== 0
          ? startTime?.hours()
          : startTime) >= moment(startTime, timeFormat).hours() &&
        (endTime !== 0 ? endTime?.hours() : endTime) <=
        moment(end, timeFormat).hours() &&
        (endTime !== 0 ? endTime?.hours() : endTime) > DEFAULT_START_HOUR
      ) {
      } else {
        form.setFieldsValue({
          date: selectedDay?.date,
          "start-time": moment(startTime, "hh:mm"),
        });
      }
    }
  }, [selectedDay, form, endTime, startTime]);

  useEffect(() => {
    const isReseller = get(selectedAppointment, "b2b_type", "") === "reseller_concierge";
    if (isReseller) {
      setWorkingDaysAndHours(DEFAULT_WORKING_HOURS_BY_DAY);
    } else if (!isEmpty(workingHours)) {
      let workingDaysAndHours = {};
      workingHours
        ?.filter((day) => day.enabled)
        .forEach((day) => {
          workingDaysAndHours[day.weekname] = {
            start: day.from,
            end: day.till,
          };
        });
      setWorkingDaysAndHours(workingDaysAndHours);
    }
  }, [workingHours, selectedAppointment]);
  const onSubmit = (form) => {
    form.validateFields().then((values) => {
      setLoading(true);
      let data = {
        id: selectedAppointment.appointment_number,
        business_id: selectedAppointment.business_id,
        location_id: selectedAppointment.location_id,
        session_date: values.date.format("YYYY-MM-DD"),
        session_time: values["start-time"].format("HH:mm"),
        session_end_time: values["end-time"].format("HH:mm"),
        rebook: "",
        quoted_fee: selectedAppointment.reschedule_fee,
        fee_reason: selectedAppointment.reschedule_fee_reason,
      };
      appointmentsAPI
        .modifyAppointment(data)
        .then((res) => {
          setWarningModalOpen(false)
          setLoading(false);
          refetch();
          queryClient.refetchQueries("appointments");
          queryClient.refetchQueries("shifts");
          queryClient.refetchQueries("businessAppointments");
          queryClient.refetchQueries("allBusinessesAppointments");
          onExit();
          openNotification(
            "Modify Appointment",
            res.data.message,
            "success"
          );
        })
        .catch(({ response }) => {
          setWarningModalOpen(false)
          setLoading(false);
          openNotification(
            "Error",
            response?.data?.data[0]?.message || response.data.message,
            "error"
          );
        });
    });
  };
  const checkDisabledDate = (current) => {
    const availbleHours =
      workingDaysAndHours[current.format("dddd")];
    if (isSootheMember) {
      return !Object.keys(workingDaysAndHours).includes(String(current.format("dddd")));
    }
    return (moment(current.format("YYYY-MM-DD")).isSame(moment().format("YYYY-MM-DD")) && moment().hour() + ADDED_MARGIN_H >= moment(availbleHours?.end, "hh:mm").hour())
      || moment().add(-1, "days") >= current
      || !Object.keys(workingDaysAndHours).includes(String(current.format("dddd")))

  }
  let availbleTimeRange = workingDaysAndHours[selectedDay?.day];

  return (<>
    <Modal
      wrapClassName={styles["modal-container"]}
      title={
        !isLoading &&
        !isLocationLoading &&
        `How would you like to modify this appointment?`
      }
      visible={isOpen}
      footer={null}
      onCancel={onExit}
      centered
    >
      {isLoading || isLocationLoading ? (
        <Skeleton active paragraph={{ rows: 11 }} />
      ) : (
        <div className="view-modal-body">
          <div className="view-modal-body-card" style={{ marginRight: 0 }}>
            <Card
              style={{
                backgroundColor:
                  selectedAppointment.status === "pending" &&
                    selectedAppointment.needs_attention
                    ? "#FFEFED"
                    : "#FAFBFF",
                color:
                  selectedAppointment.status === "pending" &&
                    selectedAppointment.needs_attention
                    ? "#C11500"
                    : "#272B41",
                marginBottom: "32px",
                border: "none",
              }}
              headless="true"
            >
              <p
                className="view-modal_section-title"
                style={{
                  color:
                    selectedAppointment.status === "pending" &&
                      selectedAppointment.needs_attention
                      ? "#C11500"
                      : "#586B94",
                }}
              >
                Appointment Details
              </p>

              <FlexRow>
                <FeatherIcon
                  icon="calendar"
                  size={16}
                  style={{
                    color:
                      selectedAppointment.status === "pending" &&
                        selectedAppointment.needs_attention
                        ? "#C11500"
                        : "#586B94",
                  }}
                  className="mr-10"
                />
                <p>
                  {moment(selectedAppointment.time.utc, "YYYY/MM/DD").format(
                    "dddd, MMM, D"
                  )}{" "}
                  at {selectedAppointment.time.display}
                </p>
              </FlexRow>
              <FlexRow>
                <FeatherIcon
                  icon="map-pin"
                  size={16}
                  style={{
                    color:
                      selectedAppointment.status === "pending" &&
                        selectedAppointment.needs_attention
                        ? "#C11500"
                        : "#586B94",
                  }}
                  className="mr-10"
                />
                <p>
                  {`${selectedAppointment.address.address_line_1}, ${selectedAppointment.address.city}`}
                  {selectedAppointment.address.hotel_room_number &&
                    ` • Room ${selectedAppointment.address.hotel_room_number}`}
                </p>
              </FlexRow>
              {selectedAppointment.booked_by && (
                <FlexRow>
                  <FeatherIcon
                    icon="user"
                    size={16}
                    style={{
                      color:
                        selectedAppointment.status === "pending" &&
                          selectedAppointment.needs_attention
                          ? "#C11500"
                          : "#586B94",
                    }}
                    className="mr-10"
                  />
                  <p>
                    Booked Appointment created by {selectedAppointment.booked_by} on{" "}
                    {moment(selectedAppointment.time.utc, "YYYY/MM/DD").format(
                      "MM/DD/YY"
                    )}
                  </p>
                </FlexRow>
              )}
              <FlexRow>
                <FeatherIcon
                  icon="shopping-cart"
                  size={16}
                  style={{
                    color:
                      selectedAppointment.status === "pending" &&
                        selectedAppointment.needs_attention
                        ? "#C11500"
                        : "#586B94",
                  }}
                  className="mr-10"
                />
                <p>
                  {selectedAppointment.number_of_providers ? `${selectedAppointment.number_of_providers} Provider${selectedAppointment.number_of_providers > 1 ? "s" : ""}` : ""}
                </p>
              </FlexRow>
            </Card>
          </div>
          <div>
            <Form
              form={form}
              name="reasons"
              onFinish={() => onSubmit(form)}
              layout="vertical"
            >
              <Row gutter={24}>
                <Col xxl={8} lg={8} md={8} xs={24}>
                  <Form.Item
                    colon={false}
                    name="date"
                    label="Date"
                    rules={[{ required: true, message: "Date is required!" }]}
                  >
                    <DatePicker
                      className={styles["select_handler"]}
                      suffixIcon={
                        <FeatherIcon
                          icon="calendar"
                          size={16}
                          color="#586B94"
                        />
                      }
                      allowClear={false}
                      onSelect={onDateSelect}
                      disabled={isEmpty(workingDaysAndHours)}
                      disabledDate={checkDisabledDate}
                      defaultValue={selectedDay?.date}
                      format={"MM/DD/YYYY"}
                    />
                  </Form.Item>
                </Col>
                <Col xxl={8} lg={8} md={8} xs={24}>
                  <Form.Item
                    colon={false}
                    name="start-time"
                    label="Start Time"
                    rules={[{ required: true, message: "Start time is required!" }]}
                  >
                    <TimePicker
                      className={styles["select_handler"]}
                      suffixIcon={
                        <FeatherIcon icon="clock" size={16} color="#586B94" />
                      }
                      popupClassName={styles["time-picker-popup"]}
                      use12Hours
                      allowClear={false}
                      disabled={!selectedDay?.day}
                      disabledTime={() =>
                        fromDisabledTime({
                          availableShiftTime: availbleTimeRange,
                          endShiftTime: moment(availbleTimeRange.end, "hh:mm A"),
                          firstDisabledHours,
                          fixedDisabledHours,
                          selectedDay: selectedDay?.date,
                          isSootheMember,
                        })
                      }
                      // todo commercial clients rule missing
                      onSelect={(time) => {
                        onSelectHourFrom({
                          time,
                          form,
                          availableShiftTime,
                          shiftHours: {
                            start: startTime,
                            end: endTime,
                          },
                          setShiftHours: ({ shiftHours }) => {
                            let { start, end } = shiftHours;
                            setStartTime(start || startTime);
                            setEndTime(end || endTime);
                            // form.setFieldsValue({
                            //   "end-time": end || endTime,
                            //   "start-time": start || startTime
                            // });
                          },
                          selectedDay: selectedDay?.date,
                          isSootheMember,
                          setLastDisabledHours,
                        })
                      }}
                      minuteStep={5}
                      inputReadOnly
                      format="h:mm A"
                      defaultValue={startTime}
                      showNow={false}
                    />
                  </Form.Item>
                </Col>
                <Col xxl={8} lg={8} md={8} xs={24}>
                  <Form.Item
                    colon={false}
                    name="end-time"
                    label="End Time"
                    rules={[{ required: true, message: "End time is required!" }, {
                      validator: (_, value) => {
                        const tempVal = value?.format("HH:mm");
                        const tempStart = startTime?.format("HH:mm");

                        if (value && startTime && tempVal <= tempStart) {
                          return Promise.reject("End time cannot be before start time");
                        }
                        return Promise.resolve();
                      }
                    }]}
                  >
                    <TimePicker
                      className={styles["select_handler"]}
                      suffixIcon={
                        <FeatherIcon icon="clock" size={16} color="#586B94" />
                      }
                      popupClassName={styles["time-picker-popup"]}
                      use12Hours
                      allowClear={false}
                      disabled={!selectedDay?.day}
                      disabledTime={() =>
                        lastDisabledTime({
                          availableShiftTime,
                          startShiftTime: moment(availbleTimeRange.start, "hh:mm A"),
                          lastDisabledHours,
                          fixedDisabledHours,
                          selectedDay: selectedDay?.date,
                          isSootheMember,
                        })
                      }
                      onSelect={(time) => {
                        onSelectHourTill({
                          time,
                          form,
                          availableShiftTime,
                          shiftHours: {
                            start: startTime,
                            end: endTime,
                          },
                          setShiftHours: ({ shiftHours }) => {
                            let { start, end } = shiftHours;
                            setStartTime(start || startTime);
                            setEndTime(end || endTime);
                            // form.setFieldsValue({
                            //   "end-time": end || endTime,
                            //   "start-time": start || startTime
                            // });
                          },
                          selectedDay: selectedDay?.date,
                          isSootheMember,
                          setFirstDisabledHours,
                        })
                      }}
                      minuteStep={5}
                      inputReadOnly
                      format="h:mm A"
                      defaultValue={endTime}
                      showNow={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <footer>
            <Button key={1} size="small" onClick={onExit} type="light">
              Back
            </Button>

            <Button
              style={{ margin: 0 }}
              size="small"
              type="sootheButton"
              onClick={() => {
                if (should_show_warning) {
                  setWarningModalOpen(true);
                } else {
                  form.submit()
                }
              }}
              key="submit"
              htmlType="submit"
              loading={loading}
            >
              Modify now
            </Button>
          </footer>
        </div>
      )}
    </Modal>
    <WarningModal isOpen={warningModalOpen} onExit={() => { setWarningModalOpen(false) }} onSubmit={() => {
      form.submit()
    }}
    />
  </>
  );
}

export default ModifyAppointmentModal;
