/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect, useReducer } from "react";
import Form from "antd/es/form";
import "antd/es/form/style";
import { useLocation, useNavigate } from "react-router-dom";
import Steps from "../../../molecules/steps/Steps";
import { useQueryClient } from "react-query";
import FooterWizardLocation from "../../../molecules/locations/FooterWizardLocation";
import About from "../About";
import Discount from "../Discount";
import Billing from "../Billing";
import Permissions from "../Permissions";
import {
  ABOUT,
  DISCOUNTS,
  BILLING,
  PERMISSIONS,
} from "../../../../utilities/constants";
import business from "../../../../utilities/api/businesses";
import billingApi from "../../../../utilities/api/billing";
import openNotification from "../../../../utilities/openNotification";
import SuccessModal from "../../SuccessModal";
import useGetBusinessById from "../../../../utilities/hooks/useGetBusinessById";
import { includes, some } from "lodash";
import VolumeDiscounts from "../VolumeDiscounts";
import useUserProfileData from "../../../../utilities/hooks/useUserProfileData";
import CancellationFees from "../CancellationFees";

const WizardForm = (props) => {
  const [businessId, setBusinessId] = useState(undefined);
  const { userData } = useUserProfileData(businessId);
  const isSootheMember = userData?.role?.isSootheMember;
  const { businessData } = props;
  const navigate = useNavigate();
  const { currentStep, setCurrentStep, steps, isEditMode, form } = props;
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [about, setAbout] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      businessName: "",
      businessPhoneNumber: "",
      businessEmail: "",
      businessLogo: "",
      businessContract: "",
    }
  );
  const [discount, setDiscount] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      discountType: "byBusiness",
      services: [],
    }
  );
  const [status, setStatus] = useState();
  const [billing, setBilling] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      billingType: "byProperty",
      emails: [],
      due_in_days: 15,
      address: {},
    }
  );
  const [permissionsData, setPermissionsData] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      selectedDealTypes: [],
      selectedPermission: [],
      businessDomain: "",
    }
  );

  const queryClient = useQueryClient();

  const [location, setLocation] = useState();
  const [checked, setChecked] = useState();
  const [loading, setLoading] = useState(false);
  const [volumeDiscounts, setVolumeDiscounts] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      invoice_discounts: [],
      // should_create: false,
      any_toggled_off: false,
    }
  );
  const [shouldSaveVD, setShouldSaveVD] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [fees, setFees] = useState([]);
  const [tempPermissions, setTempPermissions] = useState();
  const [hasResellerCategory, setHasResellerCategory] = useState(false);

  useEffect(() => {
    form.resetFields();
    if (businessData) {
      const {
        name,
        sub_domain,
        phone,
        email,
        logo,
        contract,
        discountByBusiness,
        billingByBusiness,
        permissions,
        categories,
        status,
      } = businessData;
      setStatus(status);
      setAbout({
        businessName: name,
        businessPhoneNumber: phone,
        businessEmail: email,
        businessLogo: logo,
        businessContract: contract,
      });

      setPermissionsData({
        selectedDealTypes: categories?.map((category) => category.id) || [],
        selectedPermission: permissions.map((perm) => perm.id),
        businessDomain: sub_domain,
      });

      setBusinessId(businessData?.id);
      form.setFieldsValue({
        businessName: name,
        "business-phone-number": phone,
        "business-email": email,
        "business-domain": sub_domain,
        bannerImage: logo,
        contractLogo: contract,
        providers: permissions.map((perm) => perm.id),
        "deal-type": categories?.map((category) => category.id) || [],
      });
      if (status === "draft") {
        setDiscount({
          discountType: discountByBusiness ? "byBusiness" : "byProperty",
        });
        setBilling({
          billingType: billingByBusiness ? "byBusiness" : "byProperty",
        });
        form.setFieldsValue({
          "discount-type": discountByBusiness ? "byBusiness" : "byProperty",
          "billing-type": billingByBusiness ? "byBusiness" : "byProperty",
        });
      }
    }
  }, [businessData, form]);
  const relevantVDApi = () => {
    if (shouldSaveVD && (billing.billingType === "byBusiness" || hasResellerCategory)) {
      return business.postBusinessVolumeDiscounts({
        "invoice_discounts": volumeDiscounts.invoice_discounts,
        "toggled": !volumeDiscounts.any_toggled_off
      })
    } else {
      return Promise.resolve();
    }
  }

  const relevantCPFeesApi = () => {
    if (fees.length === 0) {
      return Promise.resolve();
    }
    return billingApi.postFees({
      "cancellation_policies": fees
    })
  }

  const onSaveBilling = ({ billingDetails, goNextStep, draft }) => {
    setLoading(true);
    const {
      billingType,
      emails,
      due_in_days,
      address,
    } = billingDetails;

    const byBusiness = billingType === "byBusiness" || hasResellerCategory ? true : false;
    const billingMapping = {
      emails,
      due_in_days,
      address,
    };
    const billingMappping = {
      byBusiness,
      ...(byBusiness && billingMapping),
      ...(draft && { draft }),
    };
    return business
      .updateBilling(businessId, billingMappping)
      .then((res) => {
        relevantCPFeesApi().then(() => {
          openNotification(
            "Save Business",
            "Billing saved successfully",
            "success"
          );
          setLoading(false);
          if (draft) {
            navigate("/businesses");
          }
          setIsSuccessModal(true);
        }).catch((error) => {
          setLoading(false);
          openNotification("Save Cancellation & Reschedule Fees", error.response.data.message, "error");
        })
      })
      .catch((err) => {
        openNotification("Save Business", err?.response?.data?.message, "error");
        setLoading(false);
      });
  };
  const onSavePermissions = ({ permissions, goNextStep , draft }) => {
    setLoading(true);
    const { selectedDealTypes, selectedPermission, businessDomain } =
      permissions;

    const permisssionsMapping = {
      permissions: selectedPermission,
      dealTypes: selectedDealTypes,
      sub_domain: businessDomain,
      ...(draft && { draft }),
    };
    if (businessId) {
      return business
        .updatePermissions(businessId, permisssionsMapping)
        .then((res) => {
          setLoading(false);
          openNotification(
            "Save Business",
            "Permissions saved successfully",
            "success"
          );
          if (draft) {
            navigate("/businesses");
          }
        })
        .catch((err) => {
          openNotification("Save Business", err.response.data.message, "error");
          setLoading(false);
        });
    } else {
      setTempPermissions(permissions);
      setLoading(false);
      openNotification(
        "Save Business",
        "Permissions saved successfully",
        "success"
      );
      if (draft) {
        // this won't occur if business is not yet created
        navigate("/businesses");
      }
      goNextStep && goNextStep();
    }
  };

  const onSaveDiscount = ({ discounts, goNextStep, draft }) => {
    if (hasErrors) {
      setLoading(false);
      openNotification(
        "Save Business",
        "Please fix the errors in Volume-Based Discounts section before proceeding.",
        "error"
      );
      return;
    }
    setLoading(true);
    const { discountType, services } = discounts;
    const byBusiness = discountType === "byBusiness" || hasResellerCategory ? true : false;
    const discounServicestMapping = services.map((service) => {
      let newService = {
        product_id: service[1].id,
      };
      if (service[1].type === "amount") {
        newService.discount_amount = service[1].value;
      } else {
        newService.discount_percentage = service[1].value;
      }
      return newService;
    });
    const discountMapping = {
      byBusiness,
      ...(byBusiness && { discounts: discounServicestMapping }),
      ...(draft && { draft }),
    };
    return business
      .updateDiscounts(businessId, discountMapping)
      .then(async () => {
        relevantVDApi()
          .then(() => {
            openNotification(
              "Save Business",
              "Billing saved successfully",
              "success"
            );
            setLoading(false);
            if (draft) {
              navigate("/businesses");
            }
            goNextStep && goNextStep();
          }).catch((error) => {
            setLoading(false);
            openNotification("Save Volume-Based discounts", error.response.data.message, "error");
          });
      })
      .catch((err) => {
        openNotification("Save Business", err.response.data.message, "error");
        setLoading(false);
      });
  };
  const onSaveAboutDetails = ({ details, goNextStep, draft, businessId }) => {
    setLoading(true);
    const { selectedDealTypes, selectedPermission, businessDomain } =
      tempPermissions;

    const permisssionsMapping = {
      permissions: selectedPermission,
      dealTypes: selectedDealTypes,
      sub_domain: businessDomain,
    }
    const aboutData = {
      name: details.businessName,
      logo: details.businessLogo,
      contract: details.businessContract,
      phone: details.businessPhoneNumber,
      email: details.businessEmail,
      ...(draft && { draft }),
      ...(businessData && businessId && { isDraft: true }),
    };
    if (businessId) {
      return business
        .updateAboutDetails(businessId, aboutData)
        .then((res) => {
          openNotification(
            "Save Business",
            "About details updated successfully",
            "success"
          );
          setLoading(false);
          if (draft) {
            navigate("/businesses");
          }
          goNextStep && goNextStep();
        })
        .catch((err) => {
          openNotification("Save Business", err.response.data.message, "error");
          setLoading(false);
        });
    }
    return business
      .AddAboutDetails(businessId ? aboutData : {
        ...aboutData,
        ...permisssionsMapping
      })
      .then((res) => {
        setBusinessId(res.data.data.id);
        openNotification(
          "Save Business",
          "About details saved successfully",
          "success"
        );
        setLoading(false);
        if (draft) {
          navigate("/businesses");
        }
        goNextStep && goNextStep();
      })
      .catch((err) => {
        openNotification("Save Business", err.response.data.message, "error");
        setLoading(false);
      });
  };

  const goNextStep = () => {
    const currentIndex = steps.findIndex((step) => step === currentStep);
    const newIndex = currentIndex === steps.length - 1 ? 0 : currentIndex + 1;
    setCurrentStep(steps[newIndex]);
  };

  const onFinish = () => {
    switch (currentStep) {
      case PERMISSIONS:
        onSavePermissions({ permissions: permissionsData, goNextStep, businessId });
        break;
      case ABOUT:
        onSaveAboutDetails({ details: about, goNextStep, businessId });
        break;
      case DISCOUNTS:
        onSaveDiscount({ discounts: discount, goNextStep });
        break;
      case BILLING:
        onSaveBilling({ billingDetails: billing });
        break;
      default:
        return;
    }
    // queryClient.removeQueries(["businesses"]);
    queryClient.refetchQueries("businesses");
  };
  const onSaveAsDraft = () => {
    form.validateFields().then(() => {
      switch (currentStep) {
        case PERMISSIONS:
          onSavePermissions({
            permissions: permissionsData,
            draft: true,
            businessId,
          });
          break;
        case ABOUT:
          onSaveAboutDetails({
            details: about,
            draft: true,
            businessId,
          });
          break;
        case DISCOUNTS:
          onSaveDiscount({ discounts: discount, draft: true });
          break;
        case BILLING:
          onSaveBilling({ billingDetails: billing, draft: true });
          break;
        default:
          return;
      }
      // queryClient.removeQueries(["businesses"]);
      queryClient.removeQueries(["business"]);
      queryClient.refetchQueries("businesses");
    }).catch((error) => {
      console.log("error", error);
      openNotification("Save As Draft", error?.response?.data?.message, "error");
    });
  };

  const goPrevious = () => {
    const currentIndex = steps.findIndex((step) => step === currentStep);
    const newIndex = currentIndex === 0 ? steps.length - 1 : currentIndex - 1;
    setCurrentStep(steps[newIndex]);
  };

  const getContent = () => {
    switch (currentStep) {
      case PERMISSIONS:
        return (
          <Permissions
            form={form}
            permissionsData={permissionsData}
            setPermissionsData={setPermissionsData}
            setHasResellerCategoryInParent={setHasResellerCategory}
          />
        );
      case ABOUT:
        return <About aboutData={about} setAboutData={setAbout} form={form} />;
      case BILLING:
        return (<>
          <Billing
            status={status}
            businessId={businessId}
            billingData={billing}
            type={"business"}
            mode={"add"}
            setBillingData={setBilling}
            form={form}
            hasResellerCategory={hasResellerCategory}
          />

          {billing.billingType === "byBusiness" || hasResellerCategory
            // && some(businessData?.categories, (itm) => (includes(["Spa", "Corporate", "Concierge"], itm.name)))
            ?
            <>
              <CancellationFees
                categories={["Spa", "Corporate"]}
                setFees={setFees}
                mode="add"
                business_id={businessId} />
            </>
            : null
          }
        </>
        );
      case DISCOUNTS:
        return (<>
          <Discount
            businessId={businessId}
            form={form}
            discount={discount}
            type={"business"}
            mode={"add"}
            setDiscount={setDiscount}
            hasResellerCategory={hasResellerCategory}
          />
          {billing.billingType === "byBusiness" || hasResellerCategory
            // && some(businessData?.categories, (itm) => (includes(["Spa", "Corporate", "Concierge"], itm.name)))
            ? <>
              <div className={"divider mb-30"} />
              <VolumeDiscounts
                volumeDiscountsData={{ ...volumeDiscounts, billingType: "byBusiness", status: businessData?.status }}
                form={form}
                setVolumeDiscountsData={setVolumeDiscounts}
                mode="add"
                businessId={businessId}
                isSootheMember={isSootheMember}
                setShouldSaveVD={setShouldSaveVD}
                setHasErrors={setHasErrors}
              />
            </> : null}
        </>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [currentStep]);
  return (
    <>
      <div style={{ width: "97%" }}>
        <Steps
          current={steps.findIndex((step) => step === currentStep)}
          labelPlacement="vertical"
          steps={steps}
        />
      </div>
      <Form
        form={form}
        onFinish={onFinish}
        name="location"
        layout="vertical"
        autoComplete="off"
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
        }}
      >
        {getContent()}
        <FooterWizardLocation
          isEditMode={isEditMode}
          current={currentStep}
          steps={steps}
          prevStep={goPrevious}
          handleSaveAsDraft={onSaveAsDraft}
          locationStatus={location?.status}
          setChecked={setChecked}
          checked={checked}
          setLoading={setLoading}
          loading={loading}
        />
      </Form>

      <SuccessModal
        isOpen={isSuccessModal}
        title="Success!"
        text="You created a new business. Next, add your first property."
        buttons={[
          {
            className: "my-10",
            size: "large",

            text: "Close & add a property later",
            style: {
              backgroundColor: "#F1F6FF",
              border: "none",
              borderRadius: "5px",
            },
            onClick: () => {
              navigate("/businesses");
              openNotification(
                "Success",
                "Business created successfully",
                "success"
              );
              setIsSuccessModal(false);
            },
          },
          {
            size: "large",
            type: "primary",
            style: {
              backgroundColor: "#586B94",
              border: "none",
              borderRadius: "5px",
            },
            text: "Add property now >",
            onClick: () => {
              navigate(`/businesses/${businessId}/properties`, {
                state: {
                  mode: "business",
                  businessId: businessId,
                },
              });
              setIsSuccessModal(false);
            },
          },
        ]}
      />
    </>
  );
};

export default (props) => {
  const { state } = useLocation();
  const { businessData, isLoading: isBusinessLoading } = useGetBusinessById({
    id: state?.businessId,
  });

  return <WizardForm {...props} businessData={businessData} />;
};
