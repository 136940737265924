import React from "react";
import { Navigate, Outlet } from "react-router";
import { usePermissions } from "../../PermissionsProvider/PermissionsProvider";

const ProtectedRoutes = ({
                           to,
                           resource,
                           redirectPath = "/no-permission",
                           both = false,
                         }) => {
  const { permissions, businessPermissions } = usePermissions();

  if (!permissions && !businessPermissions) return <Outlet />;

  // Convert resource to an array if it's a string
  const resources = Array.isArray(resource) ? resource : [resource];

  // Check if user has the required permission for ANY of the given resources
  const allowedOnBusiness = resources.some(
      (res) => businessPermissions?.[res]?.includes(to)
  );

  const allowedOnUser = resources.some(
      (res) => permissions?.[res]?.includes(to)
  );

  if (both) {
    if (allowedOnBusiness && allowedOnUser) return <Outlet />;
  } else {
    if (allowedOnUser) return <Outlet />;
  }

  return <Navigate to={redirectPath} replace />;
};

export default ProtectedRoutes;
