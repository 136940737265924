/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Heading from "../../../components/atoms/heading/heading";
import { PageHeader } from "../../../components/molecules/page-header/PageHeader";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Form from "antd/es/form";
import "antd/es/form/style";
import Spin from "antd/es/spin";
import "antd/es/spin/style";
import Breadcrumb from "antd/es/breadcrumb";
import "antd/es/breadcrumb/style";
import useGetLocations from "../../../utilities/hooks/useGetLocations";
import useUserProfileData from "../../../utilities/hooks/useUserProfileData";
import { useBusinessIdGlobal } from "../../../utilities/hooks/useBusinessId";
import WizardForm from "../../../components/organisms/businesses/WizardForm";
import {
  ABOUT,
  DISCOUNTS,
  BILLING,
  PERMISSIONS,
} from "../../../utilities/constants";
import useHotjar from "../../../utilities/hooks/useHotjar";
import styles from "./style.module.scss";

function AddBusiness({ locations, businessId, refetchLocations }) {
  const steps = [PERMISSIONS, ABOUT, DISCOUNTS, BILLING];
  const [current, setCurrent] = useState(steps[0]);
  const params = useParams();
  const [form] = Form.useForm();

  return (
    <>
      <PageHeader
        className="header-boxed"
        title={<Heading as="h1">Create New Business</Heading>}
        routes={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/businesses">Businesses</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Create New Business</Breadcrumb.Item>
          </Breadcrumb>
        }
      />

      <div className="main">
        <Row gutter={15}>
          <Col lg={22} sm={24}>
            <div className={styles["card-container"]}>
              <WizardForm
                currentStep={current}
                setCurrentStep={setCurrent}
                locationId={params?.locationId}
                steps={steps}
                businessId={businessId}
                form={form}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default () => {
  useHotjar();
  const { businessId: CurrentbusinessId } = useBusinessIdGlobal();
  const { businessId, isLoading: isUserLoading } =
    useUserProfileData(CurrentbusinessId);
  const {
    locations,
    isLoading: isLocationsLoading,
    refetch: refetchLocations,
  } = useGetLocations(businessId);

  if (!locations || !businessId || isUserLoading || isLocationsLoading) {
    return (
      <div className="spin" style={{ height: "70vh" }}>
        <Spin />
      </div>
    );
  }
  const data = { businessId, locations, refetchLocations };
  return <AddBusiness {...data} />;
};
