/* eslint-disable import/no-anonymous-default-export */
import React, { Suspense, useState, useReducer } from "react";
import Spin from "antd/es/spin";
import "antd/es/spin/style";
import { Tooltip } from "antd";
import { capitalize, includes } from "lodash";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";
import Form from "antd/es/form";
import "antd/es/form/style";

import useGetTemplates from "../../../../utilities/hooks/useGetTemplates";
import Filters from "./Filters";
import Grid from "./Grid";
import List from "./List";
import { ReactComponent as DownArrowSvg } from "../../../../static/img/icon/down-arrow.svg";
import { ReactComponent as UpArrowSvg } from "../../../../static/img/icon/up-arrow.svg";
import styles from "./staffing.module.scss";
import EmptyContentView from "../../../organisms/EmptyContentView/emptyContentView";
import { Cards } from "../../../atoms/cards/Cards";
import useGetTemplateUsers from "../../../../utilities/hooks/useGetTemplateUsers";
import openNotification from "../../../../utilities/openNotification";
import DeleteTemplateModal from "./DeleteTemplateModal";
import useGetProvidersByLocation from "../../../../utilities/hooks/useGetProvidersByLocation";
import DataStateHandler from "../../../../utilities/DataStateHandler";
import TemplateModal from "../../../../pages/spaStaffing/createShift/templateModal";
import templateApis from '../../../../utilities/api/templates';
import { useLocation } from "react-router";

function TemplatesWrapper({ location, users, providers, businessId, isCorporateWellness }) {
  const [form] = Form.useForm();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [selectedCategory, setSelectedCategory] = useState("grid");
  const [sortingField, setSortingField] = useState();
  const [createdBy, setCreatedBy] = useState();
  const [modifiedBy, setModifiedBy] = useState();
  const [shiftType, setShiftType] = useState(isCorporateWellness ? "corporate" : "staffing");
  const [search, setSearch] = useState();
  const [sorting, setSorting] = useState([
    {
      field: "name",
      type: "ascending",
    },
    {
      field: "type",
      type: "ascending",
    },
    {
      field: "last_modified",
      type: "ascending",
    },
    {
      field: "created_on",
      type: "ascending",
    },
  ]);
  const [isDeleteTemplateModalOpen, setIsDeleteTemplateModalOpen] =
    useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [shiftTemplateData, setShiftTemplateData] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      isModalOpen: false,
      isRequired: false,
    }
  );
  const [shiftTitle, setShiftTitle] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      shiftTitle: "",
      isRequired: true,
      loading: false,
    }
  );

  const { templates, isLoading, totalItems, refetch, isError, error } =
    useGetTemplates({
      businessId,
      location_id: location.id,
      page: page,
      per_page: size,
      sorting: sortingField,
      created_by: createdBy,
      modified_by: modifiedBy,
      shift_type: isCorporateWellness ? "corporate" : "staffing",
      search,
    });

  const deleteTemplate = () => {
    templateApis
      .deleteTemplate(templateId, location.id, businessId)
      .then((res) => {
        setIsDeleteTemplateModalOpen(false);
        openNotification("Success", res.data.message, "success");
        refetch();
      })
      .catch(({ response }) => {
        openNotification("Error", response.data.message, "error");
      });
  };

  const onChangeCategory = (value) => {
    setSelectedCategory(value);
  };

  const handleSorting = (title, type) => {
    setSortingField({ field: title, type: type });
    setSorting((preValue) =>
      preValue.map((obj) => {
        return obj.field === title ? { ...obj, type: type } : obj;
      })
    );
  };

  const onPageChange = (newPage) => {
    setPage(newPage - 1);
  };

  const onPageSizeChange = (current, newSize) => {
    setPage(0);
    setSize(newSize);
  };

  const getColumnTitle = (title, field) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <span>{capitalize(title)}</span>

      {sorting
        .filter((item) => item.field === field)
        .map((item, index) =>
          item.type === "ascending" ? (
            <Tooltip
              key={index}
              title={"Click to sort ascending"}
              placement="top"
            >
              <DownArrowSvg
                fill={"#000"}
                onClick={() => handleSorting(field, "descending")}
                className={styles["sorting-icons"]}
              />
            </Tooltip>
          ) : (
            <Tooltip
              key={index}
              title={"Click to sort descending"}
              placement="top"
            >
              <UpArrowSvg
                fill="#000"
                onClick={() => handleSorting(field, "ascending")}
                className={styles["sorting-icons"]}
              />
            </Tooltip>
          )
        )}
    </div>
  );

  const onExit = () =>
    setShiftTemplateData({ isModalOpen: false, isRequired: false });

  const onSave = () => {
    form.validateFields().then((values) => {
      updateShiftTitle(values);
    });
  };

  const updateShiftTitle = (values) => {
    let title = values["shift-name"];
    setShiftTitle({ loading: true });
    templateApis
      .renameTemplate({
        id: templateId, details: {
          location_id: location.id,
          title,
          business_id: businessId,
        }
      })
      .then((res) => {
        openNotification("Success", res.data.message, "success");
        setShiftTitle({ loading: false });
        setShiftTemplateData({ isModalOpen: false });
        form.resetFields();
        refetch();
      })
      .catch(({ response }) => {
        setShiftTitle({ loading: false });
        openNotification("Error", response.data.message, "error");
      });
  };

  return (
    <>
      <Suspense
        fallback={
          <Cards headless>
            <Skeleton active />
          </Cards>
        }
      >
        {(templates?.length !== 0 ||
          search ||
          createdBy ||
          modifiedBy ||
          shiftType ||
          sortingField) && (
            <Filters
              selectedCategory={selectedCategory}
              onChangeCategory={onChangeCategory}
              setSortingField={setSortingField}
              sortingField={sortingField}
              setCreatedBy={setCreatedBy}
              setModifiedBy={setModifiedBy}
              setShiftType={setShiftType}
              users={users}
              templates={templates}
              setSearch={setSearch}
            />
          )}
        <DataStateHandler
          style={{ height: "68vh" }}
          isLoading={isLoading}
          loadingComponent={
            <div className="spin" style={{ height: "68vh" }}>
              <Spin />
            </div>
          }
          isError={isError}
          error={error}
          data={templates}
        >
          {!isLoading &&
            templates?.length === 0 &&
            !search &&
            !createdBy &&
            !modifiedBy &&
            !shiftType &&
            !sortingField ? (
            <EmptyContentView
              title="No Shift Templates"
              text="This location currently has no shift templates.
            Create a shift to save as a template."
              btnCopy="+ Create a Shift"
              btnLink={`/${isCorporateWellness ? "corporate-wellness" : "staffing"}/create-shift/${location.id}`}
              headingFont="h1"
            />
          ) : (
            <>
              {selectedCategory === "grid" ? (
                <Grid
                  templates={isLoading ? [] : templates}
                  isLoading={isLoading}
                  providers={providers}
                  location={location}
                  totalItems={totalItems}
                  page={page}
                  size={size}
                  onPageChange={onPageChange}
                  onPageSizeChange={onPageSizeChange}
                  setIsDeleteTemplateModalOpen={setIsDeleteTemplateModalOpen}
                  setTemplateId={setTemplateId}
                  refetch={refetch}
                  setShiftTemplateData={setShiftTemplateData}
                />
              ) : (
                <List
                  templates={isLoading ? [] : templates}
                  isLoading={isLoading}
                  providers={providers}
                  location={location}
                  totalItems={totalItems}
                  getColumnTitle={getColumnTitle}
                  page={page}
                  size={size}
                  onPageChange={onPageChange}
                  onPageSizeChange={onPageSizeChange}
                  setIsDeleteTemplateModalOpen={setIsDeleteTemplateModalOpen}
                  setTemplateId={setTemplateId}
                  refetch={refetch}
                  setShiftTemplateData={setShiftTemplateData}
                />
              )}
            </>
          )}
        </DataStateHandler>
      </Suspense>
      {isDeleteTemplateModalOpen && location && templateId && (
        <DeleteTemplateModal
          isOpen={isDeleteTemplateModalOpen}
          onExit={() => setIsDeleteTemplateModalOpen(false)}
          onSubmit={() => {
            deleteTemplate();
          }}
        />
      )}
      {shiftTemplateData.isModalOpen && (
        <Form
          layout="vertical"
          name="createShift"
          form={form}
          onFinish={onSave}
        >
          <TemplateModal
            isOpen={shiftTemplateData.isModalOpen}
            shiftTitle={shiftTitle.shiftTitle}
            setShiftTitle={setShiftTitle}
            onExit={onExit}
            onSave={onSave}
            loading={shiftTitle.loading}
          />
        </Form>
      )}
    </>
  );
}

export default ({ location, businessId }) => {
  const { pathname } = useLocation();
  const { users, isLoading, isError, error } = useGetTemplateUsers({
    location_id: location.id,
    business_id: businessId,
  });

  const {
    providers,
    isLoading: isProvidersLoading,
    isError: isProvidersError,
    error: providersError,
  } = useGetProvidersByLocation(location.id, true);

  return (
    <DataStateHandler
      style={{ height: "68vh" }}
      isLoading={isLoading || isProvidersLoading}
      loadingComponent={
        <div className="spin" style={{ height: "68vh" }}>
          <Spin />
        </div>
      }
      isError={isError || isProvidersError}
      error={error || providersError}
      data={location}
    >
      <TemplatesWrapper
        location={location}
        users={users}
        providers={providers}
        businessId={businessId}
        isCorporateWellness={includes(pathname, "corporate-wellness")}
      />
    </DataStateHandler>
  );
};
