import get from "lodash/get";
import { useQuery } from "react-query";
import providersApis from "../api/providers";
import useTryAutoLogin from "./useTryAutoLogin";
import { useLocation } from "react-router";
import { includes } from "lodash";

const useGetProvidersByLocation = (id, notReseller) => {
  const { pathname } = useLocation();
  const isCorporateWellness = includes(pathname, "corporate-wellness");
  const { isLogin } = useTryAutoLogin();
  // add test for reseller permissions

  const { data, isError, error, isLoading } = useQuery(
    ["providers", id],
    providersApis.getProvidersByLocationId,
    {
      enabled: !!isLogin && !!id && !!notReseller,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const providers = get(data, "data.data.providers", []);
  const trainings = get(data, "data.data.trainings.result", []);
  const services = get(data, "data.data.services", []);
  const workinghours = get(data, "data.data.workingHours", []);
  const breaks = isCorporateWellness ? get(data, "data.data.corporate_breaks", []) : get(data, "data.data.breaks", []);
  const parkingFees = get(data, "data.data.parkingFees", 0);
  const discounts = get(data, "data.data.discounts", 0);
  const proEquipments = get(data, "data.data.proEquipments", [])
  const timezone = get(data, "data.data.timezone", "");

  return {
    providers,
    services,
    workinghours,
    breaks,
    isError,
    error,
    isLoading,
    parkingFees,
    discounts,
    trainings,
    proEquipments,
    timezone
  };
};

export default useGetProvidersByLocation;
