import React, { useEffect, useReducer, useState } from "react";
import FeatherIcon from "feather-icons-react";
import moment from "moment-timezone";
import Form from "antd/es/form";
import "antd/es/form/style";
import Card from "antd/es/card";
import "antd/es/card/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";

import { Modal } from "../../atoms/modals/antd-modals";
import { Button } from "../../atoms/buttons/buttons";
import { FlexRow } from "../../atoms";
import Col from "antd/es/grid/col";
import DatePicker from "antd/es/date-picker";
import TimePicker from "antd/es/time-picker";
import { Row } from "antd";
import styles from "../../organisms/CreateShift/TimeAndDate/TimeAndDate.module.scss";
import {
  fromDisabledTimeNew as fromDisabledTime,
  onSelectHourFromNew as onSelectHourFrom,
  lastDisabledTimeNew as lastDisabledTime,
  onSelectHourTillNew as onSelectHourTill,
  correctStartTime
} from "../../organisms/CreateShift/TimeAndDate/helpers";
import { getMinutes } from "../../../utilities/helpers";
import useUserProfileData from "../../../utilities/hooks/useUserProfileData";
import useGetAppointmentsDetails from "../../../utilities/hooks/useGetAppointmentDetails";
import useGetLocation from "../../../utilities/hooks/useGetLocation";
import openNotification from "../../../utilities/openNotification";
import shiftApi from "../../../utilities/api/shift";
import { useQueryClient } from "react-query";
import FeeModal from "./FeeModal";
import style from "./style.module.scss";
import { concat, get, isEmpty } from "lodash";
import WarningModal from "../../organisms/CreateShift/WarningModal";
import { ADDED_MARGIN_H, FIXED_DISABLED_HOURS } from "../../../utilities/constants";
import ReasonsToRescheduleAppointmentModal from "../appointments/ReasonsToRescheduleAppointmentModal";
import useGetWaivableFees from "../../../utilities/hooks/useGetWaivableFees";

function RescheduleAppointmentModal({
  isOpen,
  onExit,
  loading,
  disabled,
  locationId,
  appointmentId,
  businessId,
  rescheduleSpaAppointment,
  setRescheduleSpaAppointment
}) {
  const queryClient = useQueryClient();

  const {
    data: selectedAppointment,
    isLoading,
    refetch
  } = useGetAppointmentsDetails({
    appointmentId,
  });

  const {
    refetch: refetchWaivableFees,
  } = useGetWaivableFees(appointmentId, true)

  const { workingHours: workingShifts, isLoading: isLocationLoading } =
    useGetLocation(locationId, businessId);

  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm";

  const [formData, setFormData] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      date: "",
      shiftHours: {
        start: 0,
        end: 0,
        diff: 0,
      },
    }
  );
  const [intermediateFromDisabled, setIntermediateFromDisabled] = useState(null);

  const { date, shiftHours } = formData;

  const [firstDisabledHours, setFirstDisabledHours] = useState([]);
  const [lastDisabledHours, setLastDisabledHours] = useState([]);
  const [availableShiftTime, setAvailableShiftTime] = useState({
    start: undefined,
    end: undefined,
  });
  const [shiftTime, setShiftTime] = useState({
    start: undefined,
    end: undefined,
  });
  const [selectDay, setSelectedDay] = useState({
    day: disabled ? "" : moment(date).format("dddd"),
    date: disabled ? "" : moment(date),
  });
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);
  const [isReasonsToRescheduleModalOpen, setIsReasonsToRescheduleModalOpen] = useState(false);
  const [reasonsLoading, setReasonsLoading] = useState(false);
  const [selectedReasonForReschedule, setSelectedReasonForReschedule] = useState();
  const [otherReasonExplanation, setOtherReasonExplanation] = useState("");

  const { userData } = useUserProfileData(businessId);
  const isSootheMember = userData?.role?.isSootheMember;
  let workingDaysAndHours = {};
  workingShifts
    ?.filter((day) => day.enabled)
    .forEach((day) => {
      workingDaysAndHours[day.weekname] = {
        start: moment(day.from, "HH:mm"),
        end: moment(day.till, "HH:mm"),
      };
    });

  const handleSetShiftHours = (date, start, end, skipTimeSet = false) => {
    const firstAppointmentAvailableHour = isSootheMember
      ? moment()
      : moment().add(ADDED_MARGIN_H, "hours");
    const firstAppointmentEnabledHour = start;
    const lastAppointmentEnabledHour = end;

    if (date.isSame(moment(), "day")) {
      if (
        firstAppointmentAvailableHour.hour() <
        firstAppointmentEnabledHour.hour()
      ) {
        let extraValues = skipTimeSet ? {} : {
          start: moment().set({
            hours: firstAppointmentEnabledHour.hour(),
            minutes: firstAppointmentEnabledHour.minute(),
          }),
          end: firstAppointmentEnabledHour.add(
            moment.duration(shiftHours.diff, "hours")
          )
        }
        setFormData({
          shiftHours: {
            ...shiftHours,
            ...extraValues
          },
        });
        return;
      }
      if (
        firstAppointmentAvailableHour.hour() > lastAppointmentEnabledHour.hour()
      ) {
        let extraValues = skipTimeSet ? {} : {
          start: moment().set({
            hours: lastAppointmentEnabledHour.hour() - 1,
            minutes: 0,
          }),
          end: lastAppointmentEnabledHour.add(
            moment.duration(shiftHours.diff, "hours")
          )
        }
        setFormData({
          shiftHours: {
            ...shiftHours,
            ...extraValues
          },
        });
        return;
      }
      let hours = isSootheMember
        ? firstAppointmentAvailableHour.minute() > 15
          ? firstAppointmentAvailableHour.hour() + 1
          : firstAppointmentAvailableHour.hour()
        : firstAppointmentAvailableHour.minute() > 45
          ? firstAppointmentAvailableHour.hour() + 1
          : firstAppointmentAvailableHour.hour();
      let minutes = isSootheMember
        ? firstAppointmentAvailableHour.minute() >= 30
          ? getMinutes(firstAppointmentAvailableHour.minute() - 30)
          : getMinutes(firstAppointmentAvailableHour.minute() + 30)
        : getMinutes(firstAppointmentAvailableHour.minute());
      let extraValues = skipTimeSet ? {} : {
        start: moment().set({
          hours: hours,
          minutes: minutes,
        }),
        end: moment()
          .set({
            hours: hours,
            minutes: minutes,
          })
          .clone()
          .set({
            minutes: minutes,
          })
          .add(moment.duration(shiftHours.diff, "hours"))
      }
      setFormData({
        shiftHours: {
          ...shiftHours,
          ...extraValues
        },
      });
      return;
    } else {
      let extraValues = skipTimeSet ? {} : {
        start: moment(start, "HH:mm"),
        end: firstAppointmentEnabledHour.add(
          moment.duration(shiftHours.diff, "hours")
        )
      }
      setFormData({
        shiftHours: {
          ...shiftHours,
          ...extraValues
        },
      });
    }
  };

  const handleRescheduleSpaAppointment = (form) => {
    form.validateFields().then((values) => {
      let data = {
        id: selectedAppointment.appointment_number,
        businessId: businessId,
        locationId: selectedAppointment.location_id,
        session_date: values.date.format("YYYY-MM-DD"),
        session_time: values["start-time"].format("HH:mm"),
        session_end_time: values["end-time"].format("HH:mm"),
        quoted_fee: selectedAppointment.reschedule_fee,
        fee_reason: selectedAppointment.reschedule_fee_reason,
        rebook: ""
      };
      if (!values["start-time"].isBefore(values["end-time"])) {
        openNotification("Error", "End time should be greater than start time", "error");
        return;
      }
      if (selectedAppointment.reschedule_fee_reason) {
        setRescheduleSpaAppointment({
          isModalLoading: false,
          isFeeModalOpen: true,
          data,
          form,
        });
      } else {
        setIsReasonsToRescheduleModalOpen(true);
        setRescheduleSpaAppointment({
          isModalLoading: false,
          isFeeModalOpen: false,
          data,
          form,
        });
      }
      setWarningModalIsOpen(false)
    }).catch((error) => {
      setWarningModalIsOpen(false)
    });
  };

  const onRescheduleSpaAppointment = (data, form, subPayload) => {
    data = {
      ...data,
      ...subPayload
    }
    shiftApi
      .rescheduleShift(data)
      .then((res) => {
        setReasonsLoading(false);
        form.resetFields();
        refetch();
        refetchWaivableFees();
        queryClient.refetchQueries("getWaivableFees");
        queryClient.refetchQueries("appointments");
        queryClient.refetchQueries("shifts");
        queryClient.refetchQueries("businessAppointments");
        queryClient.refetchQueries("allBusinessesAppointments");
        openNotification("Reschedule Appointment", res.data.message, "success");
        setIsReasonsToRescheduleModalOpen(false);
        setRescheduleSpaAppointment({
          isModalLoading: false,
          isModalOpen: false,
          isFeeModalLoading: false,
          isFeeModalOpen: false,
        });
        onExit();
      })
      .catch(({ response }) => {
        setReasonsLoading(false);
        setIsReasonsToRescheduleModalOpen(false);
        openNotification("Error", response.data.message, "error");
        setRescheduleSpaAppointment({
          isModalLoading: false,
          isFeeModalLoading: false,
        });
      });
  };

  const { appointment_number, time, cart_end_time, providers_json, session_date } =
    selectedAppointment || {};

  let availbleTimeRange = workingDaysAndHours[selectDay?.day];

  useEffect(() => {
    if (!intermediateFromDisabled && selectDay && shiftTime.start && shiftTime.end) {
      let intermediateFromDisabled = fromDisabledTime({
        availableShiftTime: availbleTimeRange,
        endShiftTime: moment(shiftTime.end, "hh:mm A"),
        firstDisabledHours,
        fixedDisabledHours: concat(FIXED_DISABLED_HOURS, [22]),
        selectedDay: selectDay?.date,
        isSootheMember,
      })
      setIntermediateFromDisabled(intermediateFromDisabled)
      correctStartTime({
        start: shiftTime.start, disabledHoursFunc: intermediateFromDisabled.disabledHours, disabledMinutesFunc: intermediateFromDisabled.disabledMinutes,
        updateStartTime: (start) => {
          setFormData({
            shiftHours: { ...shiftHours, start },
          });
          form.setFieldsValue({
            "start-time": start,
          });

          setAvailableShiftTime({ start, end: shiftTime.end });
          setShiftTime({ start, end: shiftTime.end });
          handleSetShiftHours(selectDay?.date, start, shiftTime.end, isEmpty(date));
        }
      })
    }
  }, [intermediateFromDisabled, selectDay, shiftTime, availbleTimeRange])

  return (
    <>
      <Modal
        wrapClassName={`${style["modal"]} ${style["navy-modal"]}`}
        title={
          !isLoading &&
          !isLocationLoading &&
          `When would you like to reschedule #${appointment_number} to?`
        }
        visible={isOpen && !rescheduleSpaAppointment.isFeeModalOpen && !isReasonsToRescheduleModalOpen && !warningModalIsOpen}
        footer={null}
        onCancel={onExit}
        centered
      >
        {isLoading || isLocationLoading ? (
          <Skeleton active paragraph={{ rows: 11 }} />
        ) : (
          <div className="view-modal-body">
            <div className="view-modal-body-card" style={{ marginRight: 0 }}>
              <Card
                style={{
                  marginBottom: "32px",
                  border: "none",
                }}
                headless="true"
              >
                <p
                  className="view-modal_section-title"
                  style={{
                    color: "#586B94",
                  }}
                >
                  #{appointment_number}
                </p>

                <FlexRow>
                  <FeatherIcon
                    icon="calendar"
                    size={16}
                    color={"#586B94"}
                    className="mr-10"
                  />
                  <p>
                    {moment(session_date, "YYYY-MM-DD").format("dddd, MMM D")} at{" "}
                    {time?.display}
                    {" - "}
                    {cart_end_time?.display}
                  </p>
                </FlexRow>
                <FlexRow>
                  <FeatherIcon
                    icon="map-pin"
                    size={16}
                    color={"#586B94"}
                    className="mr-10"
                  />
                  <p>
                    {selectedAppointment?.address?.address_line_1},{" "}
                    {selectedAppointment?.address?.city}
                  </p>
                </FlexRow>
                <FlexRow>
                  <FeatherIcon
                    icon="shopping-cart"
                    size={16}
                    color={"#586B94"}
                    className="mr-10"
                  />
                  <p>
                    {providers_json?.length}{" "}
                    {providers_json?.length > 1 ? "Providers" : "Provider"}{" "}
                    Confirmed, offers will be resent
                  </p>
                </FlexRow>
              </Card>
            </div>
            <div className="mb-20">
              <Form
                form={form}
                name="reschedule"
                onFinish={() => {
                  if (moment(selectedAppointment?.cart_end_time?.utc).isBefore(moment())) {
                    setWarningModalIsOpen(true)
                  } else {
                    handleRescheduleSpaAppointment(form)
                  }
                }}
                layout="vertical"
              >
                <Row gutter={16}>
                  <Col md={8} sm={24}>
                    <Form.Item
                      className={styles["date-container"]}
                      colon={false}
                      name="date"
                      label="Date"
                      rules={[{ required: true, message: "Date is required" }]}
                    >
                      <DatePicker
                        initialValues={date && moment(date)}
                        value={moment(date)}
                        allowClear={false}
                        className={`${styles["date-picker"]} ${styles["date-picker-input"]}`}
                        onSelect={(dateNw) => {
                          const day = moment(dateNw).format("dddd");
                          const { start, end } = workingDaysAndHours[day];
                          setSelectedDay({ day, date: dateNw });
                          setFormData({ date: dateNw });
                          setAvailableShiftTime({ start, end });
                          setShiftTime({ start, end });
                          handleSetShiftHours(dateNw, start, end, isEmpty(date));
                          setIntermediateFromDisabled(null)
                        }}
                        disabledDate={(current) => {
                          const availbleHours = workingDaysAndHours[current.format("dddd")];

                          return (
                            current.isBefore(moment(), "day") ||
                            !Object.keys(workingDaysAndHours).includes(
                              String(current.format("dddd"))
                            ) ||
                            (current.isSame(moment(), "day")
                              && moment().add(ADDED_MARGIN_H, "hours").isSameOrAfter(availbleHours?.end)
                            )
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={24}>
                    <Form.Item
                      colon={false}
                      name="start-time"
                      label="Start Time"
                      rules={[
                        { required: true, message: "Start Time is required" },
                      ]}
                    >
                      <TimePicker
                        className={styles["date-picker"]}
                        popupClassName={styles["time-picker-popup"]}
                        use12Hours
                        inputReadOnly
                        format="hh:mm A"
                        minuteStep={5}
                        disabled={!intermediateFromDisabled}
                        showNow={false}
                        allowClear={false}
                        disabledTime={() => {
                          return intermediateFromDisabled || {}
                        }
                        }
                        onSelect={(time) => {
                          return onSelectHourFrom({
                            time,
                            setShiftHours: setFormData,
                            availableShiftTime,
                            form,
                            shiftHours,
                            selectedDay: selectDay?.date,
                            isSootheMember,
                            setLastDisabledHours,
                            endShiftTime: moment(shiftTime.end, "hh:mm A"),
                            firstDisabledHours,
                            fixedDisabledHours: concat(FIXED_DISABLED_HOURS, [22]),
                          })
                        }
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col md={8} sm={24}>
                    <Form.Item
                      style={{ flexGrow: 1 }}
                      colon={false}
                      name="end-time"
                      label="End Time"
                      rules={[
                        { required: true, message: "End Time is required" }, {
                          validator: (_, value) => {
                              const tempVal = value?.format("HH:mm");
                              const tempStart = shiftHours?.start?.format("HH:mm");

                              if (value && shiftHours?.start && tempVal <= tempStart) {
                                  return Promise.reject("End time cannot be before start time");
                              }
                              return Promise.resolve();
                          }
                      }
                      ]}
                    >
                      <TimePicker
                        className={styles["date-picker"]}
                        popupClassName={styles["time-picker-popup"]}
                        use12Hours
                        allowClear={false}
                        inputReadOnly
                        format="hh:mm A"
                        showNow={false}
                        disabled={!selectDay?.date}

                        disabledTime={() =>
                          lastDisabledTime({
                            availableShiftTime,
                            startShiftTime: moment(shiftTime.start, "hh A"),
                            lastDisabledHours,
                            fixedDisabledHours: FIXED_DISABLED_HOURS,
                            selectedDay: date,
                            isSootheMember,
                          })
                        }
                        onSelect={(time) => {
                          setIntermediateFromDisabled(null)
                          onSelectHourTill({
                            time,
                            setShiftHours: setFormData,
                            availableShiftTime,
                            form,
                            shiftHours,
                            selectedDay: date,
                            isSootheMember,
                            setFirstDisabledHours,
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>

                </Row>
              </Form>
            </div>
            <footer>
              <Button key={1} size="small" onClick={onExit} type="light">
                Close without saving
              </Button>

              <Button
                style={{ margin: 0 }}
                size="small"
                type="sootheButton"
                onClick={() => form.submit()}
                key="submit"
                htmlType="submit"
                loading={loading}
              >
                Reschedule and resend offers now
              </Button>
            </footer>
          </div>
        )}
      </Modal>
      {rescheduleSpaAppointment.isFeeModalOpen && (
        <FeeModal
          isOpen={rescheduleSpaAppointment.isFeeModalOpen}
          loading={rescheduleSpaAppointment.isFeeModalLoading}
          title={"Are you sure you want to reschedule?"}
          body={
            <div>
              <p>
                Rescheduling will put the shift in a pending state until the selected Providers accept the new changes.
              </p>
              <p>You're {get(selectedAppointment, "reschedule_fee_reason", "")}</p>
              <p>
                <span style={{ fontWeight: 500 }}>You will be charged {selectedAppointment.reschedule_fee} for rescheduling this appointment.</span> Are you sure you want to reschedule this appointment?
              </p>
            </div>
          }
          action="reschedule"
          onExit={onExit}
          onSubmit={() => {
            setIsReasonsToRescheduleModalOpen(true);
            setRescheduleSpaAppointment({
              isFeeModalOpen: false,
            });
          }}
        />
      )}

      {isReasonsToRescheduleModalOpen && (
        <ReasonsToRescheduleAppointmentModal
          loading={reasonsLoading}
          setLoading={setReasonsLoading}
          isOpen={isReasonsToRescheduleModalOpen}
          appointmentId={selectedAppointment.appointment_number}
          businessId={selectedAppointment.business_id}
          onExit={() => {
            setIsReasonsToRescheduleModalOpen(false);
          }}
          selectedReasonForReschedule={selectedReasonForReschedule}
          setSelectedReasonForReschedule={setSelectedReasonForReschedule}
          setOtherReasonExplanation={setOtherReasonExplanation}
          otherReasonExplanation={otherReasonExplanation}
          handleSubmit={(subPayload) => {
            setReasonsLoading(true);
            onRescheduleSpaAppointment(
              rescheduleSpaAppointment.data,
              rescheduleSpaAppointment.form,
              subPayload
            );
          }}
        />
      )}
      <WarningModal isOpen={warningModalIsOpen} onExit={() => { setWarningModalIsOpen(false) }} onSubmit={() => {
        handleRescheduleSpaAppointment(form)
      }} />
    </>
  );
}

export default RescheduleAppointmentModal;
