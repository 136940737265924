import { usePermissions } from "./PermissionsProvider";

const Restricted = ({ both = false, to, resource, children, fallback }) => {
  const { permissions, businessPermissions } = usePermissions() || {};
  const resources = Array.isArray(resource) ? resource : [resource];

  const allowedOnBusiness = resources.some(
      (res) => businessPermissions?.[res]?.includes(to)
  );

  const allowedOnUser = resources.some(
      (res) => permissions?.[res]?.includes(to)
  );

  if (both && allowedOnBusiness && allowedOnUser) {
    return children;
  }

  if (!both && allowedOnUser) {
    return children;
  }

  return fallback;
};

export default Restricted;
